.js-slick-channel-slider {
    //js should group rows into pairs
    &__slide {

        //js should group images into rows of 4
        &-row {
            width: 100%;
            max-width: 400px;
        }
    }
}

.slick-channel-slider {
    text-align: center;
    max-width: 100%;

    //it's only a slick slider on mobile.$larger screens it's just a row of images


    &__image {
        max-width: 24%;
        display: inline-block;
        vertical-align: middle;
        padding: rem(10);
        img {
            max-height: 70px;
            max-width: 70px;
        }
    }

    @include breakpoint($small) {
        text-align: left;
        &__image {
            display: inline-block;
            vertical-align: middle;
            padding: rem(10);
            img { margin: 0; max-width: 100%; }
        }
    }

    @include breakpoint($medium) {
        &__image {
            width: 16%;
        }
    }

    @include breakpoint($large) {
        margin-left: rem(40);
        &__image {
            max-width: 12%;
        }
    }
}