.line-item {
    //layout
    display: table;
    width: 100%;
    margin-bottom: rem(10);
    &-main {
      margin-bottom: 0;
    }

    &__label {
        display: table-cell;
        vertical-align: top;
        font-size: rem(16);
        font-weight: 300;
        &.right {
          text-align: right;
          width: 75%;
          margin-right: rem(20);
          font-weight: 500;
          vertical-align: bottom;
          @include breakpoint($medium) {
            width: 85%;
          }

        }
        &.expiration {
          font-size: rem(12);
          font-weight: 500;
        }
        @include breakpoint($medium) { font-size: rem(16); }

    }
    &__price {
        display: table-cell;
        vertical-align: top;
        text-align: right;
        font-size: rem(18);
         @include breakpoint($medium) { font-size: rem(16); }

      &.price-wrap--old {
        .price-wrap__amount {
          display: inline-block;
          font-weight: 600;
          font-size: rem(18);
          span, sup {
            font-size: rem(12);
            position: relative;
            top: -4px;
            transform: translate(0);
          }
          &:before {
            top: rem(-2);
          }
        }
      }
    }
    &__remove {
        display: table-row;
    }
    &__expiration {
        display: table-row;
        font-size: rem(18);
        font-weight: 500;
         @include breakpoint($medium) { font-size: rem(16); }

        &-title {
            font-weight: 700;
        }
    }
    &__promo {
      border-top: 2px solid #e5e5e5;
      &__top {
        display: flex;
        margin: rem(20) 0;
        &__image {
          max-width: 40%;
          margin-right: rem(14);
        }
        &__text p {
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &__bottom {
        p {
          font-size: rem(14);
        }
        button {
          margin: 0;
        }
      }
    }
    &__disclaimer {
      display: block;
      font-size: rem(12);
      max-width: 70%;
    }

    //typography
    // @include breakpoint($large) { font-size: rem(18); }
}
