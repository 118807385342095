.pagination {
    &-pag {
        &__btn {
            -webkit-appearance: none;
            border: 0;
            padding: 0;
            background: transparent;
            position: relative;
            font-size: rem(16);
            font-weight: 500;

            &:not([disabled]) {
                color: $blue-link;
                cursor: pointer;
            }
            &:disabled {
                color: $mine-shaft;
            }
        }

        &__list {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            text-align: center;
            align-items: center;
            margin: rem(30) 0;

            @include breakpoint($medium) {
                flex-wrap: nowrap;
            }
        }

        &__item {
            line-height: 1;
            border: 2px solid transparent;
            order: 1;
            &--prev,
            &--next {
                flex: 0 0 auto;
            }

            &--next {
                order: 2;
            }
        }

        &__link {
            -webkit-appearance: none;
            border: 0;
            padding: 0;
            background: transparent;
            padding: rem(2) rem(10);
            text-decoration: underline;
            color: $blue-link;
            cursor: pointer;

            &[aria-current="true"] {
                color: $mine-shaft;
                text-decoration: none;
                cursor: default;
            }
        }
    }
}