@font-face {
    font-family: XfinityStandard;
    src: url('../../../assets/fonts/XfinityStandard-Thin.woff2') format('woff2'),url('../../../assets/fonts/XfinityStandard-Thin.woff') format('woff'),url('../../../assets/fonts/XfinityStandard-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal
}

@font-face {
    font-family: XfinityStandard;
    src: url('../../../assets/fonts/XfinityStandard-ThinItalic.woff2') format('woff2'),url('../../../assets/fonts/XfinityStandard-ThinItalic.woff') format('woff'),url('../../../assets/fonts/XfinityStandard-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic
}

@font-face {
    font-family: XfinityStandard;
    src: url('../../../assets/fonts/XfinityStandard-ExtraLight.woff2') format('woff2'),url('../../../assets/fonts/XfinityStandard-ExtraLight.woff') format('woff'),url('../../../assets/fonts/XfinityStandard-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal
}

@font-face {
    font-family: XfinityStandard;
    src: url('../../../assets/fonts/XfinityStandard-ExtraLightItalic.woff2') format('woff2'),url('../../../assets/fonts/XfinityStandard-ExtraLightItalic.woff') format('woff'),url('../../../assets/fonts/XfinityStandard-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic
}

@font-face {
    font-family: XfinityStandard;
    src: url('../../../assets/fonts/XfinityStandard-Light.woff2') format('woff2'),url('../../../assets/fonts/XfinityStandard-Light.woff') format('woff'),url('../../../assets/fonts/XfinityStandard-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: XfinityStandard;
    src: url('../../../assets/fonts/XfinityStandard-LightItalic.woff2') format('woff2'),url('../../../assets/fonts/XfinityStandard-LightItalic.woff') format('woff'),url('../../../assets/fonts/XfinityStandard-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic
}

@font-face {
    font-family: XfinityStandard;
    src: url('../../../assets/fonts/XfinityStandard-Regular.woff2') format('woff2'),url('../../../assets/fonts/XfinityStandard-Regular.woff') format('woff'),url('../../../assets/fonts/XfinityStandard-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: XfinityStandard;
    src: url('../../../assets/fonts/XfinityStandard-RegularItalic.woff2') format('woff2'),url('../../../assets/fonts/XfinityStandard-RegularItalic.woff') format('woff'),url('../../../assets/fonts/XfinityStandard-RegularItalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic
}

@font-face {
    font-family: XfinityStandard;
    src: url('../../../assets/fonts/XfinityStandard-Medium.woff2') format('woff2'),url('../../../assets/fonts/XfinityStandard-Medium.woff') format('woff'),url('../../../assets/fonts/XfinityStandard-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: XfinityStandard;
    src: url('../../../assets/fonts/XfinityStandard-MediumItalic.woff2') format('woff2'),url('../../../assets/fonts/XfinityStandard-MediumItalic.woff') format('woff'),url('../../../assets/fonts/XfinityStandard-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic
}

@font-face {
    font-family: XfinityStandard;
    src: url('../../../assets/fonts/XfinityStandard-Bold.woff2') format('woff2'),url('../../../assets/fonts/XfinityStandard-Bold.woff') format('woff'),url('../../../assets/fonts/XfinityStandard-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: XfinityStandard;
    src: url('../../../assets/fonts/XfinityStandard-BoldItalic.woff2') format('woff2'),url('../../../assets/fonts/XfinityStandard-BoldItalic.woff') format('woff'),url('../../../assets/fonts/XfinityStandard-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic
}

@font-face {
    font-family: XfinityStandardCondensed;
    src: url('../../../assets/fonts/XfinityStandardCondensed-Medium.woff2') format('woff2'),url('../../../assets/fonts/XfinityStandardCondensed-Medium.woff') format('woff'),url('../../../assets/fonts/XfinityStandardCondensed-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: XfinityStandardCondensed;
    src: url('../../../assets/fonts/XfinityStandardCondensed-Bold.woff2') format('woff2'),url('../../../assets/fonts/XfinityStandardCondensed-Bold.woff') format('woff'),url('../../../assets/fonts/XfinityStandardCondensed-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal
}
