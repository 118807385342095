.permission-error {

    .main {
        padding-top: 0;
    }

    &__content {
        margin-top: rem(40);
        p {
            font-size: rem(16);
            font-weight: 200;
            a {
                font-size: rem(16);
                font-weight: 500;
            }
        }
        dl {
            padding: rem(10) 0 0;
        }
        dt {
            margin: 0 0 rem(3);
            font-size: rem(12);
            font-weight: 500;
        }
        dd {
            margin: 0 0 rem(24);
            font-size: rem(14);
            font-weight: 300;
        }
        .note {
            font-size: rem(12);
            font-weight: 400;
            line-height: 1.8;
            a {
                font-weight: 500;
            }
        }
    }

}
