@mixin visually-hidden {
    position: absolute;
    display: block;
    visibility: visible;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
}

.visually-hidden,
.vh {
  @include visually-hidden;
}