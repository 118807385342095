.refill-tab {
    .card {
        margin: 0;
    }

    &-button {
        font-weight: 200;
        font-size: rem(14);
        width: 50%;
        text-align: center;
        background-color: #E3E7E9;
        border: none;
        border-top: 4px solid #E3E7E9;
        border-bottom: 1px solid #979797;
        display: inline-block;
        -webkit-appearance: none;
        padding: rem(10) 0;
        white-space: normal;
        cursor: pointer;

        &:hover {
            border-top: 4px solid #E3E7E9;
            background-color: #E3E7E9;
        }

        &.active {
            background-color: $white;
            border-bottom: 1px solid $white;
            border-top: 4px solid $cta-blue;
        }
    }

    &-details {
        padding: rem(30) rem(15);
    }

    &-title {
        font-size: rem(18);
        line-height: rem(24);
        font-weight: 400;
        margin-bottom: rem(10);
    }

    &-body {
        font-size: rem(14);
        line-height: rem(22);
        font-weight: 200;
    }
    .pin-refill {
        img {
            display: block;
            margin: rem(20) auto;
        }
        &-success {
            .card__header-title {
                margin-bottom: rem(20);
                &:before {
                    content: '';
                    display: inline-block;
                    position: relative;
                    top: 10px;
                    left: 0;
                    width: 40px;
                    height: 40px;
                    margin-right: rem(15);
                    background-image: url("../../assets/img/icon-check-circle.svg");
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
        }
        &-header {
            font-size: rem(12);
            line-height: rem(18);
            font-weight: 500;
            margin-top: rem(15);
        }
        &-detail {
            font-size: rem(14);
            line-height: rem(22);
            font-weight: 200;
        }
    }
}