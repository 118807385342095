.footer {
  background-color: $cod-gray;
  color: white;
  padding: rem(40) $site-padding;
  text-align: center;
  .dashboard &, .edit-payment & {
    padding: rem(40) $site-padding rem(80);
    @include breakpoint($large) {
      padding: rem(40) rem(130);
    }
  }

  @at-root .sticky-cart__body & {
    margin: 0 spacing('xs', -1);
  }

  @include breakpoint($large) {
    padding: rem(40) rem(130);
  }

  &__width-constraint {
    margin: 0 auto;
    width: 100%;
  }

  &__flex {
    @include breakpoint($large) {
      display: flex;
      // align-content: center;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__logo,
  &__utils {
    @include breakpoint($large) {
      flex: 0 1 auto;
    }
  }

  &__logo {
    // margin: rem(30) 0;
    margin:rem(20) auto 0;
    display: none;

    @include breakpoint($large) {
      margin: 0;
      display: block;
    }

    .comcast-logo {
      width: 100px;
      height: 34px;
      background: url(../../assets/img/comcast.svg);
      background-repeat: no-repeat;
      background-size: contain;

      margin: 0 auto;

      @include breakpoint($large) {
        margin: 0 0 rem(20);
      }
    }
  }

  &__utils {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: rem(14);

    li {
      display: inline-block;
      border-right: 1px solid #fff;
      margin-right: rem(13);
      margin-bottom: rem(20);
      padding-right: rem(13);
      font-size: rem(12);
      line-height: 1.5;

      @include breakpoint($medium) {
        font-size: rem(16);
        line-height: 1.5;
        margin-bottom: rem(20);
      }

      @include breakpoint($large) {
        font-size: rem(16);
        line-height: 1.5;
      }

      &:last-child {
        border: 0;
        margin: 0;
        padding: 0;
      }
    }

    a {
      // padding: 0 rem(10);
      white-space: nowrap;
      text-decoration: none;
      font-size: inherit;


      &, &:hover {
          color: white;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &.buyflow {
    padding: rem(40) rem(15) rem(120);
    @include breakpoint($large) {
      padding: rem(40) rem(130);
    }
  }
}
