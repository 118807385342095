.status-circle {
    position: relative;
    display: inline-block;
    text-align: center;
    margin: rem(15) 0;
    &-content {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
    &-days {
        font-size: rem(24);
        line-height: rem(30);
        font-weight: 500;
        
        .service-status & {
            font-weight: 700;
        }
        .accordion__tab & {
            @include breakpoint($large) {
                font-size: rem(24);
                line-height: rem(24);
                font-weight: 500;
            }
        }
    }
    &-label {
        text-transform: uppercase;
        font-size: rem(12);
        font-weight: 500;
        .dashboard-xitv & {
            font-size: rem(10);
            line-height: rem(12);
            @include breakpoint($large) {
                font-size: 8px;
                font-weight: 500;
            }
        }
    }
    .icon {
        width: rem(90);
        height: rem(90);
        &-expired {
            padding: rem(5);
        }
        .dashboard-xitv & {
            width: rem(55);
            height: rem(55);
        }
    }
    .icon-warning-tooltip {
        position: absolute;
        width: rem(20);
        height: rem(20);
        padding: 0;
        top: 8%;
        right: 0;
        z-index: 2;
        img {
            width: 100%;
            height: auto;
        }
        &:focus {
            outline: none;
        }
    }
    .toolTip {
        display: none;
        padding: 10px;
        color: $tundora;
        background-color: $white;
        font-weight: 500;
        text-align: left;
        position: absolute;
        left: 110%;
        top: -17%;
        z-index: 2;
        border-radius: 5px;
        min-width: 200px;
        &::after {
            content: "";
            position: absolute;
            top: 30px;
            left: -5px;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent $white transparent transparent;
        }
    }
    .icon-warning-tooltip:focus + .toolTip {
        display: block;
    }
    &.paused {
        &::after {
            content: '';
            position: absolute;
            background-image: url("../../assets/img/blue-pause.svg");
            height: 20px;
            width: 20px;
            right: 0;
            top: 10px;
        }
    }
}