.litepay-page {
  .main {
    padding-top: rem(60);
  }
  p {
    font-size: rem(14);
    font-weight: 200;
    @include breakpoint($medium) {
      font-size: rem(18);
    }
    a {
      font-size: rem(14);
      text-decoration: none;
      @include breakpoint($medium) {
        font-size: rem(18);
      }
    }
  }
  .modal-item {
    .button--link--large {
      text-transform: none;
      font-size: rem(12);
      @include breakpoint($medium) {
        font-size: rem(14);
      }
    }
  }
  h4 {
    font-size: rem(14);
    font-weight: 400;
    @include breakpoint($medium) {
      font-size: rem(18);
    }
  }
  .card {
    margin: 0;
  }
  .accordion-refill {
    border-top: 1px solid #d1d1d1;
    .accordion__panel {
      &[aria-hidden="true"] {
        border-top: none;
    }
    }
    .accordion__tab {
      font-weight: 300;
      font-size: rem(18);
      color: #303030;
      position: relative;
      &::before {
        content: '';
        height: 100%;
        position: absolute;
        border-left: 1px solid #D8D8D8;
        top: 0;
        left: rem(30);
        z-index: 1;
      }
      &-first {
        &::before {
          height: 50%;
          top: 50%;
        }
      }
      &-last {
        &::before {
          height: 50%;
          top: 0;
        }
      }
    }
    .number {
      display: inline-block;
      position: relative;
      border-radius: 50%;
      width: rem(40);
      height: rem(40);
      padding: rem(8);
      border: 1px solid #D1D1D1;
      color: #303030;
      background-color: white;
      text-align: center;
      font-size: rem(24);
      font-weight: 200;
      margin-right: rem(10);
      vertical-align: middle;
      z-index: 2;
      &:focus {
        border-color: #D1D1D1;
      }
      @include breakpoint($large) {
        padding: rem(4);
      }
      .expanded & {
        border-color: $cta-blue;
        color: $cta-blue;
      }
    }
    img {
      &.check {
        width: rem(40);
        height: rem(40);
        margin-right: rem(10);
        display: inline-block;
        vertical-align: middle;
        position: relative;
        z-index: 2;
        background-color: white;
      }
    }
    .expanded {
      border-color: $cta-blue;
      &.accordion__tab {
        color: #303030;
      }
      .number {
        border-color: $cta-blue;
        color: $cta-blue;
      }
      &.complete {
        border-color: $success;
        &:focus {
          border-color: $success;
        }
        .number {
          border-color: $success;
          color: $success;
        }
      }
    }
    .complete {
      border-color: $success;
      &.accordion__tab {
        color: #303030;
      }
      &:focus {
        border-color: $success;
      }
      .number {
        border-color: $success;
        color: $success;
      }
    }
    .accordion__panel {

      .form-field__title-example {
        font-weight: 200;
      }

      p {
        font-size: rem(14)!important;
        font-weight: 200;
        @include breakpoint($medium) {
          font-size: rem(18)!important;
        }
      }
      .checkbox__label {
        p {
          margin-top: 0;
        }
      }
    }
    .accordion__panel-content {
      padding: rem(20) 0;
    }
    #review-order .checkbox__label {
      position: relative;
      &::before {
        margin-top: rem(5);
      }
      p {
        padding-top: 0;
      }
    }
    #review-order {
      .card {
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
      .card__header {
        margin-top: rem(20);
      }
      .checkbox {
        margin: 20px 0;
        .validation-error {
          top: 0;
          margin-bottom: rem(10);
        }
      }
    }
    .card {
      &__header {
        border-bottom: 1px solid #d1d1d1;
      }
      &.cc-form-recap {
        margin: 0 rem(20);
      }
      .cart {
        margin-bottom: rem(30);
      }
      .card {
        h2 {
          padding-bottom: 0;
          padding-left: 0;
          max-width: 100%;

          @include breakpoint($large) {
            padding-bottom: 1.25rem;
          }
        }
        padding: 0;
        width: 100%;
        margin: 0 0 20px 0;

        &--order-subtotal {
          border-top: 1px solid #d1d1d1;
          padding-top: 1.25rem;
          padding-bottom: 1.25rem;
          
          &::after {
            background-color: #d1d1d1;
            content: '';
            display: block;
            height: 1px;
            margin: 1.25rem 0 0 auto;
            max-width: 180px;
            width: 60%;
          }
        }
      }
    }
    button.next-step {
      display: block;
      min-width: 80%;
      margin: 0 auto;
      @include breakpoint($medium) {
        min-width: rem(125);
        margin-left: 0;
      }
    }
    button {
      &:disabled {
        background-color: white;
        border: 1px solid #D1D1D1;
        color: #D1D1D1;
      }
    }
  }

  .faq-answer {
    padding: rem(20) 0;
  }
  .litepay-confirmation {
    .card {
      &.cc-form-recap {
        margin: 0 rem(15);
      }
    }
    .cart {
      .card {
        margin: 0 rem(-15);
        &.card--order-total {
          padding-right: rem(15);
          @include breakpoint($medium) {
            padding-right: rem(35);

          }
        }
      }
    }
    .accordion__panel {
      position: relative;
      z-index: 0;
      .print-save {
        z-index: 1;
        right: rem(10);
        top: rem(13);
        @include breakpoint($medium) {
          right: rem(40);
          top: rem(20);

        }
      }
    }
    .accordion__faq-group {
      position: relative;
      .link-callout {
        top: rem(14);
        right: rem(15);
        text-transform: none;
      }
    }
  }
  .button-captcha {
    display: block;
    margin: 0 auto;
  }
  @media print {
    #offCanvasTop {
      display: none;
    }
    .litepay-confirmation {
      h1 {
        display: block;
      }
      background: none;
      .cc-form-recap {
        display: block;
        background: none;
        color: black;
        font-size: rem(20);
      }
      .accordion__faq-group {
        display: none;
      }
    }
    .footer {
      display: none;
    }

  }
  .cc-form-recap {
    font-size: rem(16);
    img {
      margin-right: rem(5);
      vertical-align: middle;
      position: relative;
    }
    &-header {
      font-size: rem(14);
      font-weight: 500;
      margin-top: rem(10);
    }
  }
}
