.link-list {
    padding: rem(15);
    background-color: $white;
    .card & {
        padding: 0;
    }

    @include breakpoint ($large) {
        padding: rem(70) 0;
    }

    &-title {
        font-size: rem(18);
        line-height: rem(24);
        font-weight: 200;
        color: $mine-shaft;
        text-align: left;
        padding: 0;

        @include breakpoint ($large) {
            font-size: rem(24);
            line-height: rem(32);
            font-weight: 100;
            margin-bottom: 0;
        }
    }

    &-items {
        padding: 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;

        .modal-sub-title {
            font-size: rem(12);
            font-weight: 500;
            line-height: rem(18);
            color: $tundora;
            margin-bottom: rem(20);
        }

        .modal-images {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;

            &::after {
                content: '';
                flex: auto;
            }

            .modal-image {
                position: relative;
                display: inline-block;
                padding: rem(20);
                margin-right: rem(10);
                margin-bottom: rem(10);
                border: 1px solid #E3E7E9;
                height: 90px;
                width: 90px;

                img {
                    width: 60px;
                    height: auto;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                @include breakpoint ($large) {
                    width: 90px;
                    height: 90px;
                }
            }
        }
    }

    &-item {
        display: inline-block;
        background-color: $white-haze;
        border: 1px solid $porcelain;
        border-radius: 4px;
        padding: rem(30) 0;
        margin-right: 15px;
        text-align: center;
        margin-top: rem(15);
        width: 47%;
        flex: 1 0 47%;
        text-decoration: none;
        &:hover {
            opacity: 0.8;
        }

        &:last-child {
            margin-right: 0;
        }

        &:nth-child(even) {
            margin-right: 0;
        }

        @include breakpoint ($large) {
            flex: 1 0 15.5%;
            margin-bottom: 0;
            margin-right: 12px;
            &:nth-child(even) {
                margin-right: 12px;
            }
            &:last-child {
                margin-right: 0;
            }
        }

        &-icon {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            display: inline-block;
            position: relative;
            margin-bottom: rem(10);

            img {
                width: 55px;
                height: auto;
                max-height: 55px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &-description {
            color: $tundora;
            font-size: rem(12);
            font-weight: 500;
            line-height: rem(18);
        }

        a {
            &:hover {
                cursor: pointer;
            }

            .feature-list-item {
                &-description {
                    color: $cta-blue;
                }
            }
        }
    }
}
