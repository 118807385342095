.payment-history {
    &-accordion {
        .accordion {
            padding: 0 0 rem(5) 0;
            &__panel {
                padding: 0;
            }
            &__content {
                padding: 0;
                .card {
                    margin: 0;
                    padding: rem(20);
                }
            }
            &-date {
                font-size: rem(12);
                line-height: rem(18);
                font-weight: 500;
                &-detail {
                    font-size: rem(14);
                    line-height: rem(22);
                    font-weight: 200;
                    margin-left: rem(20);
                }
            }
            &-amount {
                font-size: rem(12);
                line-height: rem(18);
                font-weight: 500;
                &-detail {
                    font-size: rem(16);
                    line-height: rem(20);
                    font-weight: 500;
                    margin-left: rem(20);
                }
            }
            .line-item {
                &.expiration {
                    margin: rem(20) 0 0;
                }
            }
        }
    }
}