.notification-card {
  .item {
    width: 100%;
    background: white;
    position: relative;
    margin: 0 0 spacing('xs', 0.5);
    padding: spacing('sm') rem(15) spacing('sm');
    box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.33);
    // font-size: rem(16);
    .dashboard-home & {
      margin: 0;
    }


    > :last-child { margin-bottom: 0; } //an attempt to get the bottom margin to be "universal"

    @include breakpoint($medium) {
      padding: spacing('sm') spacing('sm', 0.5);
      // margin: rem($v-gap) 0;
      margin: 0 0 spacing('xs', 0.5);
    }

    @include breakpoint($large) {
      padding-left: spacing('sm');
      padding-right: spacing('sm');
    }
    &.warning {
      border-left: 2px solid $warning;
      .item-title-badge {
        background-color: $warning;
        &:before {
          background-image: url(../../assets/img/icon-alert.svg);
          background-size: contain;
        }
      }
    }

    &-title-badge {
      position: absolute;
      left: 0;
      top: rem(25);
      background-color: $porcelain;
      min-width: rem(55);
      min-height: rem(35);
      border-bottom-right-radius: rem(8);
      border-top-right-radius: rem(8);
      @include breakpoint ($large) {
        top: rem(25);
      }
      &:before {
        content: "";
        background-image: url('../../assets/img/icon-account-black.svg');
        background-repeat: no-repeat;
        background-size: cover;
        content: "";
        display: inline-block;
        position: relative;
        top: 7px;
        left: rem(22);
        margin-right: rem(8);
        width: 22px;
        height: 22px;
      }
    }
    &-title {
      position: relative;
      margin-left: rem(60);
      font-size: rem(14);
      font-weight: 600;
      @include breakpoint ($large) {
        font-size: rem(16);
        margin-left: rem(50);
      }
    }

    &-body {
      margin-top: rem(20);
    }

    &-actions {
      margin: rem(10) auto 0;
      max-width: rem(400);
      @include breakpoint($large) {
        max-width: rem(900);
      }
      a {
        text-decoration: none;
        font-size: rem(14);
        font-weight: 500;
        &:last-of-type {
          float: right;
        }
      }
    }

  }
}
