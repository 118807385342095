h1,
.h1 {
    font-size: rem(28);
    font-weight: 100;
    margin: 0 0 rem(7);

    // @include breakpoint($medium) {
    //     font-size: rem(30);
    // }

    @include breakpoint($large) {
        font-size: rem(50);
    }
}

h2,
.h2 {
    font-size: rem(24);
    line-height: rem(32);
    font-weight: 100;
    margin: 0 0 rem(7);

    // @include breakpoint($medium) {
    //     font-size: rem(36);
    // }

    @include breakpoint($large) {
        font-size: rem(32);
        line-height: rem(42);
    }
}

h3,
.h3 {
    font-size: rem(18);
    font-weight: 300;
    margin: 0 0 rem(7);
    line-height: 1.2;

    // @include breakpoint($medium) {
    //     font-size: rem(20);
    // }

    @include breakpoint($large) {
        font-size: rem(24);
    }
}

h4,
.h4 {
    font-size: rem(16);
    font-weight: 400;
    margin: 0 0 rem(7);

    // @include breakpoint($medium) {
    //     font-size: rem(16);
    // }

    @include breakpoint($large) {
        font-size: rem(16);
    }
}

h5,
.h5 {
    font-size: rem(12);
    font-weight: 500;
    margin: 0 0 rem(7);

    // @include breakpoint($medium) {
    //     font-size: rem(16);
    // }

    @include breakpoint($large) {
        font-size: rem(12);
    }
}

p,
p a {
    font-size: rem(16);
    font-weight: 300;
    margin: 0 0 rem(10);
    line-height: 1.5;

    @include breakpoint($large) {
        font-size: rem(18);
    }
}
.disclaimer {
    padding: rem(20) 0;
    font-size: rem(12);
    color: $cod-gray;
    p {
        font-size: rem(12);
        color: $cod-gray;
    }
}

.p {
    font-size: rem(14);
}

a {
    color: $cta-blue;
    font-size: rem(12);
}


.b1 {
    font-size: rem(16);
    margin-bottom: rem(7);
    font-weight: 300;
}

.b2 {
    font-size: rem(14);
    margin-bottom: rem(7);
}

.b3 {
    font-size: rem(12);
    margin-bottom: rem(7);
}

.l1 {
    font-size: rem(12);
    font-weight: 500;

    @include breakpoint($large) {
        // font-size: rem(12);
    }
}

.lead-in {
    font-size: rem(18);
    // @include breakpoint($medium) {
    //     font-size: rem(20);
    // }
    @include breakpoint($large) {
        font-size: rem(18);
    }
}

.address-text {
    font-size: rem(18);
}


.form-section-header {
    font-size: rem(18);
    font-weight: 400;

    &--subform {
        font-size: rem(12);
        font-weight: 500;
        border-bottom: 2px solid $fill-grey;
        margin-bottom: spacing('sm');
        display: inline-block;
        width: 100%;
        padding-bottom: rem(20);
    }
}


hr {
    display: block;
    margin: rem(15) 0;
    padding: 0;
    height: 2px;
    border: 0;
    background-color: $fill-grey;
}

embed,
img,
object,
video {
    max-width: 100%;
    vertical-align: middle;
}

address,
dl,
ul {
    margin: 0;
    padding: 0;
}

dd,
ul {
    margin-left: 1.5em;
}

li ol,
li ul {
    margin-bottom: 0;
}

li {
    margin: 0;
    padding: 0;
}

b,
strong {
    font-weight: 700;
}

code {
    border: 1px solid $fill-grey;
    background: white;
    padding: 0 2px;
    display: inline-block;
}

ol {
    list-style-type: none;
    padding-left: 35px;
    font-size: rem(14);
    line-height: 25px;
    margin: 0;
    li {
        position: relative;
        margin: rem(20) 0;
    }
    li:before {
        content: " ";
        position: absolute;
        left: -35px;
        top: 0;
        width: 25px;
        height: 25px;
        border: 1px solid #9d9d9d;
        border-radius: 50%;
    }
    li:after {
        font-size: rem(12);
        line-height: 25px;
        color: #9d9d9d;
        position: absolute;
        left: -35px;
        width: 25px;
        text-align: center;
        top: 0;
    }
    li:first-child {
        margin-top: 0;
    }
    li:last-child {
        margin-bottom: 0;
    }
    @for $i from 1 through 10 {
        li:nth-child(#{$i}):after {
            content: "#{$i}";
        }
    }
    @include breakpoint($medium) {
        font-size: rem(14);
    }
    @include breakpoint($large) {
        font-size: rem(14);
    }
}

ol.basic {
    padding-left: 28px;
    counter-reset: item;
    li {
        margin: 0;
        &:before {
            content: counter(item) ") ";
            counter-increment: item;
            left: -16px;
            width: auto;
            height: auto;
            border: none;
        }
        &:after {
            content: none;
        }
    }
}