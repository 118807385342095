.buyflow-prepaid {
    .cart {
        &__display-large {
            display: none;
            @include breakpoint ($large) {
                display: block;
            }
        }
        &__display-small-medium {
            display: block;
            @include breakpoint ($large) {
                display: none;
            }
        }
    }
}