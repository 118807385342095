.edit-payment {
    .payment-method {
        &-header {
            font-size: rem(12);
            line-height: rem(18);
            font-weight: 500;
            margin-top: rem(15);
        }
        &-detail {
            font-size: rem(16);
            line-height: rem(22);
            font-weight: 200;
        }
        &-icon {
            position: relative;
            width: 32px;
            height: auto;
            margin-left: rem(20);
            top: -2px;
        }
        .flex-item {
            position: relative;
        }
        .payment-input-icon {
            position: absolute;
            width: 32px;
            height: auto;
            top: 50%;
            right: rem(20);
            transform: translate(0, -40%);
        }
        .form-field-cvv {
            .modal-item {
                display: inline;
            }
        }
        .card {
                padding-left: 0;
                padding-right: 0;
                padding-top: 0;
                margin: 0 0 1.25rem;
            }
        .checkbox {
            margin-bottom: rem(20);
            &__label:before {
                top: rem(-3);
            }
        }
        .billing-address {
            text-transform: uppercase;
            font-size: rem(16);
            font-weight: 200;
        }
        .form-buttons {
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            margin-top: rem(40);
            &.has-delete-card {
                grid-template-columns: 1fr 1fr;
                .button {
                    margin: 0;
                }
            }
            @include breakpoint($medium) {
                &.has-delete-card {
                    grid-template-columns: 1fr 1fr 1fr;
                    .button {
                        margin: 0 auto;
                    }
                }
            }
            .button--link {
                margin: 0;
            }
        }
        .auto-refill-selection {
            margin-top: rem(20);
            .checkbox__label:before {
                top: 5px;
            }
        }
    }
    .page__title{
        text-align:center;
        padding:30px 0;
    }
}
#payment-form {
    .form-field-cvv {
        .modal-item {
            display: inline;
        }
    }
}