.address-list {
    margin: rem(20) 0 rem(50);

    fieldset {
        display: flex;
        flex-direction: column;
        margin: rem(10) auto rem(30);
    }

    &__item {
        margin-bottom: rem(10);
    }

    input {
        width: 0;
        height: 0;
        margin: 0;
        opacity: 0;
        &:checked {
            + label {
                border-left-color: $cta-blue;
            }
        }
    }

    label {
        padding: rem(20) rem(15) rem(20) rem(40);
        border-left: 5px solid $white-haze;
        border-radius: 3px;
        background: #fff;
        box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);
        font-weight: 300;
        font-size: rem(18);
        &::before {
            top: 50%;
            transform: translate(0, -50%);
            left: rem(10);
        }
        span {
            display: block;
            font-size: rem(12);
        }
    }

    .button {
        margin: 0 auto;
    }

}