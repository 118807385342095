.merchant-prepaid {
    .header__width-constraint {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
    }
    .main {
        padding: 0;
    }
    .main__width-constraint {
        padding: 0;
        max-height: auto;
    }
    .footer {
        margin-top: rem(70);
    }
    &__white-bg {
        background-color: $white;
        padding: rem(20) rem(0) rem(10);
    }
    &__banner {
        padding: rem(30) rem(20);
        &--yellow {
            background-color: $warning;
        }
        &--green {
            background-color: $merchant-prepaid-green;
        }
        &--no-top-pad {
            padding: rem(0) rem(20) rem(30);
        }
        &--image {
            margin-bottom: 200px;
            @include breakpoint($medium) {
                display: flex;
                align-items: center;
                margin-bottom: rem(30);
            }
        }
        &--sub {
            padding-bottom: rem(0);
        }
    }
    &__title {
        font-size: rem(28);
        line-height: rem(38);
        font-weight: 700;
        margin: 0;
        padding-bottom: rem(10);
    }
    &__subtitle {
        font-size: rem(18);
        line-height: rem(24);
        margin: 0;
        padding-bottom: rem(10);
    }

    &__helptext {
        font-size: rem(12);
        line-height: rem(18);
        font-weight: 500;
        a {
            font-size: rem(12);
            line-height: rem(18);
            font-weight: 500;
            text-decoration: none;
            border: none;
            background: none;
            color: $cta-blue;
            padding: 0;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__banner-address {
        padding: rem(20) 0 0;
        border-top: 1px solid $alto;
        display: flex;
        align-items: flex-start;
    }
    &__unserviceable-address {
        padding: rem(20) 0;
        margin: 0 rem(20);
        border-bottom: 1px solid $alto;
    }
    &__check-icon {
        position: relative;
        width: 30px;
        height: 30px;
        border: 1px solid $success;
        border-radius: 100%;
        &:after {
            content: "";
            position: absolute;
            top: 8px;
            left: 6px;
            width: 15px;
            height: 7px;
            border-bottom: 1px solid $success;
            border-left: 1px solid $success;
            transform: rotate(-45deg);
        }
    }
    &__error-icon {
      flex-shrink: 0;
      height: auto;
      margin-right: rem(10);
      width: 30px;
    }
    &__multi-address-item {
        padding: 0 rem(20);
    }
    &__eligible-address {
        margin: rem(8) 0 0 rem(10);
        p {
            font-size: rem(12);
            line-height: rem(16);
            font-weight: 500;
            margin: 0;
        }

        button {
            font-size: rem(12);
            line-height: rem(18);
            font-weight: 500;
            text-decoration: none;
            border: none;
            background: none;
            color: $cta-blue;
            padding: 0;
            text-decoration: underline;
        }

        .merchant-prepaid__unserviceable-address & {
            align-items: center;
            display: flex;
        }
    }
    &__banner-image {
        display: block;
        width: 100%;
        margin-bottom: -200px;
        @include breakpoint($medium) {
            width: 40%;
            margin-bottom: 0;
            margin-left: 30px;
        }
        img {
            display: block;
            margin: auto;
            width: 100%;
            min-height: 200px;
            max-width: 100%;
        }
    }

    .support-modal-wrapper {
        display: inline;
        margin-bottom: 0;
        width: auto;
    }
    &__bodylink {
        font-size: rem(12);
        line-height: rem(18);
        font-weight: 500;
        text-decoration: none;
        border: none;
        background: none;
        color: $cta-blue;
        padding: 0;
        &.side-space {
            padding: 0 rem(10);
        }
    }
    &__bodylink:hover {
        text-decoration: underline;
        cursor: pointer;
    }
    &__form {
        &.card {
            margin: 0 0 rem(20);
        }
        label {
            font-size: rem(12);
            line-height: rem(18);
            font-weight: 500;
            padding-bottom: rem(5);
        }
        input {
            margin: 0;
        }

        .validation-error {
            color: $error;
            font-size: rem(14);
        }
    }
    &__radio {
        display: block;
        position: relative;
        margin-bottom: rem(15);
        cursor: pointer;
        user-select: none;
        &--select {
            position: absolute;
            top: 0;
            left: 0;
            height: 24px;
            width: 24px;
            background-color: $black-haze;
            border-radius: 100%;
            border: 1px solid $alto;
            transition: all 0.25s ease;
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ .merchant-prepaid__radio--select {
                background-color: $cta-blue;
                border-color: $cta-blue;
                &:after {
                    content: "";
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    background-color: $white;
                    border-radius: 100%;
                    top: 6px;
                    left: 6px;
                }
            }
        }
    }
    &__checkbox {
        display: block;
        position: relative;
        padding-left: rem(30);
        padding-top: rem(7);
        padding-bottom: 0 !important;
        margin-bottom: 0;
        cursor: pointer;
        user-select: none;
        &--select {
            position: absolute;
            top: 3px;
            left: 0;
            height: 24px;
            width: 24px;
            background-color: $white;
            border-radius: 4px;
            border: 1px solid $alto;
            transition: all 0.25s ease;
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ .merchant-prepaid__checkbox--select {
                background-color: $cta-blue;
                border-color: $cta-blue;
                &:after {
                    content: "";
                    position: absolute;
                    top: 5px;
                    left: 4px;
                    width: 15px;
                    height: 7px;
                    border-bottom: 1px solid $white;
                    border-left: 1px solid $white;
                    transform: rotate(-45deg);
                }
            }
        }

        &:hover {
            &--select {
                background-color: $fill-grey;
            }
        }
    }
    &__form-row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0 rem(20) rem(20);
        &--full {
            width: 100%;
            flex-shrink: 0;
            flex-grow: 0;
            position: relative;
        }
        &--half {
            width: calc(50% - 10px);
            flex-shrink: 0;
            flex-grow: 0;
            position: relative;
        }
    }
    &__your-account {
        text-align: center;
    }
    &__address-autofill {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: $white;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
        margin: 0;
        padding: 0 rem(10);
        list-style: none;
        z-index: 1;
        &--button {
            padding: rem(10);
            color: $cta-blue;
            border: none;
            display: block;
            width: 100%;
            background: none;
            text-align: left;
            font-style: rem(16);
            font-weight: normal;
            &:hover,
            &.active {
                background-color: $black-haze;
                cursor: pointer;
            }
        }
        &--option {
            padding: rem(10);
            border-bottom: 1px solid $alto;
            display: block;
            width: 100%;
            &:hover,
            &.active {
                background-color: $black-haze;
                cursor: pointer;
            }
        }
        &--street {
            font-size: rem(16);
            display: block;
            font-weight: 200;
        }
        &--city {
            font-size: rem(12);
            display: block;
            font-weight: 500;
        }
    }
    &__button-wrap {
        padding: rem(30) rem(20) rem(0);
        text-align: center;
        &--small-top {
            padding: rem(20) rem(20) 0;
        }
        button {
            min-width: 200px;
            margin: rem(20);
            &:first-of-type {
                margin: 0 rem(10);
            }
            &:disabled {
                background-color: transparent;
                border: 1px solid $alto;
                color: $alto;
            }
        }
    }
    &__chat {
        font-size: rem(12);
        font-weight: 500;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: rem(15);
        &:hover {
            text-decoration: underline;
        }
        &--icon {
            width: 24px;
            margin: 0 rem(10);
        }
    }
    &__check {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);

        &--content {
            text-align: center;
            font-size: rem(14);
            max-width: 400px;
            margin: auto;
            display: block;
        }
        .progress-spinner__loader {
            height: 200px;
        }
    }
    &__back {
        color: $cta-blue;
        border: none;
        background: none;
        position: relative;
        padding: rem(20) rem(40);
        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 20px;
            width: 10px;
            height: 10px;
            border-left: 1px solid $cta-blue;
            border-top: 1px solid $cta-blue;
            transform: translateY(-50%) rotate(-45deg);
        }
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
    &__code-entry {
        padding: rem(20);
        &--sm-top-pad {
            padding: rem(10) rem(20) rem(20);
        }
    }
    &__callout {
        margin: rem(20) 0 0;
        background-color: $white;
        border-left: 5px solid $success;
        border-radius: 3px;
        padding: rem(10) rem(15);
        &--title {
            font-size: rem(14);
            font-weight: bold;
            margin: 0;
            line-height: 1.3;
        }
        &--copy {
            font-size: rem(14);
            font-weight: 400;
            margin: 0;
            line-height: 1.3;
        }
        @include breakpoint($medium) {
            border-left-color: $white;
        }
    }
    &__faq {
        padding: rem(20) 0;
        .accordion__faq-group {
            margin: rem(20) 0;
        }
    }
    &__faq-headline {
        font-size: rem(18);
        margin: 0;
        padding: 0 rem(20);
    }
    &__heads-up {
        margin-top: rem(8);
        display: flex;
        align-items: center;
        img {
            width: 50px;
            height: auto;
        }
        &__message {
            margin: 0 0 0 rem(12);
            font-size: rem(14);
        }
        &__cta {
            padding: 0 rem(30) 0 rem(5);
            font-size: rem(14);
            color: $mine-shaft;
            text-decoration: underline;
        }
    }
    &__error {
        padding: rem(20);

        background-color: $warning;
        @include breakpoint($medium) {
            display: flex;
            align-items: flex-start;
            justify-content: center;
        }
        &--content {
            display: flex;
            align-items: flex-start;
            img {
                width: 25px;
                flex-grow: 0;
                flex-shrink: 0;
                margin: rem(10) 0;
            }
        }
        &--body-content {
            padding: 0 0 0 rem(15);
            @include breakpoint($medium) {
                padding: 0 rem(20);
                max-width: 500px;
            }
        }
        &--headline {
            font-size: rem(16);
            font-weight: 500;
            line-height: 1.3;
        }
        &--example {
            margin: rem(10) 0;
            @include breakpoint($medium) {
                width: 30%;
                flex-grow: 0;
                flex-shrink: 0;
                margin: rem(10);
            }
        }
    }
    &__block {
        padding: rem(20) 0;
    }
    .check-availability {
        &-pag {
            &__btn {
                -webkit-appearance: none;
                border: 0;
                padding: 0;
                background: transparent;
                position: relative;
                font-size: rem(16);
                font-weight: 500;

                &:not([disabled]) {
                    color: $blue-link;
                    cursor: pointer;
                }
            }

            &__list {
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                text-align: center;
                align-items: center;
                margin: rem(30) 0;

                @include breakpoint($medium) {
                    flex-wrap: nowrap;
                }
            }

            &__item {
                line-height: 1;
                border: 2px solid transparent;
                order: 1;
                &--prev,
                &--next {
                    flex: 0 0 auto;
                }

                &--next {
                    order: 2;
                }
            }

            &__link {
                -webkit-appearance: none;
                border: 0;
                padding: 0;
                background: transparent;
                padding: rem(2) rem(10);
                text-decoration: underline;
                color: $blue-link;
                cursor: pointer;

                &[aria-current="true"] {
                    color: $mine-shaft;
                    text-decoration: none;
                    cursor: default;
                }
            }
        }
    }
}
