.icon {
	display: inline-block;
	width: 18px;
	height: 18px;

	&.icon-x {
		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background: url('../../../assets/img/icon-x.svg');
		}
	}
}
