
.alert-banner {
	background-color: $banner-alert;
	padding: rem(40) 0;
	left: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	max-width: 100vw;
	position: relative;
	right: 50%;
    width: 100vw;
    &.alert-banner__bottom {
        position: fixed;
        bottom: 0;
        text-align: left;
        margin-bottom: 0;
		padding: rem(10);
		@include breakpoint($medium) {
			padding: rem(10) 0;
		}
	}
	&.alert-banner-success {
		background-color: $success;
		margin: 0;
		width: 100%;
		left: 0;
		right: 0;
		padding: rem(20) 0;
		.icon, .text {
			display: inline;
			vertical-align: middle;
			font-size: rem(16);
			img {
				margin: 0 rem(10) 0 ;
				@include breakpoint($large) {
					margin: 0 rem(10) 0  0;
				}
			}
		}

	}

	.page--basic-content & {
		margin-bottom: rem(30);
	}

	@include breakpoint($large) {
		padding: rem(40) 0;
		margin-bottom: rem(80);
	}


	img {
		display: inline-block;
		height: rem(20);
		width: rem(20);
		margin-right: rem(15);
		vertical-align: top;
		margin-top: rem(10);

		@include breakpoint($medium) {
			height: rem(22);
			width: rem(22);
			top: rem(5);
			margin-top: 0;
		}
	}
	&-title {
		display: inline-block;
		font-size: rem(14);
		max-width: 90%;
	}

	&-body {
		font-size: rem(16);
		padding: rem(10) 0;
		font-weight: 300;
	}

	&-link {
		text-decoration: none;
		font-weight: 600;
		font-size: rem(12);

		@include breakpoint($medium) {
			font-weight: 500;
			font-size: rem(16);
		}
	}
}

