.page {

  &__header {
    margin-bottom: rem(30);
    margin-top: rem(20);
    // padding: 0 rem(10);

    @include breakpoint($medium) {
      margin-bottom: rem(40);
      margin-top: 0;
    }

    @include breakpoint($large) {
      margin-bottom: rem(50);
      // padding: 0 rem(24);
    }
  }

  &__title {
      font-size: rem(24);
      line-height: rem(32);
      font-weight: 700;
      @include breakpoint($large) {
          font-size: rem(40);
          line-height: rem(50);
      }
      .refill & {
        padding: 0 rem(15);
        @include breakpoint($large) {
          padding: 0;
        }
      }
      &--large {
        display: block;
        font-size: 1.5rem;
        line-height: 2rem;
        margin: rem(10) 0 rem(10);
        @include breakpoint($large) {
          font-size: 2.5rem;
          line-height: 3.125rem;
        }
      }
  }

  &__sub-text,
  &__sub-title {
    margin-top: rem(10);
    font-size: rem(16);
    font-weight: 300;
    line-height: 1.4;

  @include breakpoint($large) {
        font-size: rem(18);
    }
  }

  &__body {
    margin-top: rem(10);
  }
}