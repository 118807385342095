
@keyframes slideInFromBottom {
    0% {
        transform: translateY(20%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes pulse_animation {
  0% { transform: scale(1); }
  30% { transform: scale(1); }
  40% { transform: scale(1.08); }
  50% { transform: scale(1); }
  60% { transform: scale(1); }
  70% { transform: scale(1.05); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
}
.service-detail {
    .main__width-constraint {
        position: relative;
    }
    .autoEnrollSection {
        color: $white;
        font-size: rem(20);
        font-weight: 900;
        padding: rem(25) rem(20);
        text-decoration: none;
    }
    .autoEnrollSection a {
        color: $white;
        text-decoration: none;
    }
    &-top {
        min-height: rem(230);
        padding: rem(40) rem(20) rem(20) rem(20);
        background: $mine-shaft url('../../assets/img/status-background-M.png') no-repeat;
        background-size: cover;
        text-align: center;
        color: $white;
        position: relative;

        @include breakpoint($medium) {
            background: $mine-shaft url('../../assets/img/status-background-D.png') no-repeat;
            background-size: cover;
        }
        .status-circle {
            .icon-warning-tooltip {
                right: 10%;
            }
            .toolTip {
                top: -5%;
                left: 95%;
                min-width: 115px;
                @include breakpoint($medium) {
                    top: -8%;
                    min-width: 200px;
                }
            }
        } 
    }
    &-bottom {
      .main__width-constraint {
          background-color: $white;
          position: relative;
          white-space: nowrap;
          padding: rem(20);

      }
        &-left {
            display: inline-flex;
            align-items:center;
            width: 50%;
            font-size: rem(16);
            position: relative;
            .refill-label {
                font-weight: 700;
                font-size: rem(12);
                padding-right:rem(10);
            }
        }
        &-right {
            display: inline-block;
            width: 50%;
            text-align: right;
          font-weight: 500;
            .service-status-label {
                &:before {
                    content:"\A";
                    margin-right: 5px;
                    width:10px;
                    height:10px;
                    border-radius:50%;
                    display:inline-block;
                    background: currentColor;
                }
            }
        }
      &-reset-block {
        width: 100%;
        border-top: 1px solid $alto;
        text-align: center;
        padding: rem(20);
        margin-top: rem(20);
        a {
          text-decoration: none;
        }
      }
    }
    .status-circle {
        animation: slideInFromBottom ease 2s;
        margin-top: rem(30);
        &-days {
            font-size: rem(65);
            line-height: rem(80);
        }
        &-label {
            font-size: rem(12);
        }
    }

    .icon.icon-expired {
      margin-top: rem(30);
      height: rem(115);
      width: rem(115);

      @include breakpoint($large) {
        height: rem(150);
        width: rem(150);
      }
    }
    .pulse {
      animation-name: pulse_animation;
      animation-duration: 3000ms;
      transform-origin:70% 70%;
      animation-iteration-count: 2;
      animation-timing-function: linear;
    }
    .back-button {
        text-align: left;
        position: absolute;
        top: rem(0);
        left: rem(20);
        color: $white;
        text-decoration: none;
        padding-left: rem(20);
        background: none;
        outline: none;
        border: none;
        cursor: pointer;
        &::before {
            border-style: solid;
            border-color: white;
            border-width: 1px 1px 0 0;
            content: '';
            display: inline-block;
            height: 10px;
            width: 10px;
            top: 5px;
            left: 0;
            position: absolute;
            transform: rotate(-135deg);
        }
    }
    .title {
        position: absolute;
        top: rem(0);
        right: rem(20);
        font-size: rem(14);
        font-weight: 500;
    }

    &__expired {
        .icon.icon-expired {
            height: rem(115);
            width: rem(115);

            @include breakpoint($large) {
                height: rem(150);
                width: rem(150);
            }
        }
        &-warning {
            padding: rem(20) 0;
            font-size: rem(18);
            font-weight: 200;
            @include breakpoint($large) {
                font-size: rem(24);
            }
        }
    }
    .button--link--underline {
        color: $cta-sky;
    }
    .button--hollow-white {

        min-width: rem(190);
        margin-top: rem(30);
    }

    &__active {
        position: relative;
        text-align: left;
        &-item {
            padding: rem(0) rem(20);
            margin-top: rem(10);
            position: relative;
            display: block;
            width: 100%;
            background-color: rgba(0,0,0,0.2);
            animation: slideInFromBottom ease 2s;
            @include breakpoint($medium) {
                display: inline-block;
                width: 49%;
                min-height: rem(150)
            }
            &-internet {
                @include breakpoint($medium) {
                    margin-right: 1%;
                }
            }
            &-content {
                display: inline-block;
                vertical-align: top;
                margin: rem(15) 0;
                width: 65%;
                text-decoration: none;
                color: $white;
                position: relative;
                &::before {
                    border-style: solid;
                    border-width: 1px 1px 0 0;
                    content: '';
                    display: inline-block;
                    height: 20px;
                    width: 20px;
                    top: 20px;
                    right: 0;
                    position: absolute;
                    transform: rotate(45deg);
                    @include breakpoint($medium) {
                        top: 50%;
                        transform: rotate(45deg) translate(0, -50%);
                        right: 20px;
                    }
                    @include breakpoint($large) {
                        right: 10px;
                    }
                }
                .title {
                    margin: rem(5);
                    font-size: rem(16);
                    font-weight: 700;
                }
                .expiration {
                    margin: rem(5);
                    font-size: rem(14);
                    font-weight: 300;
                    text-transform: uppercase;
                }
                .button {
                    width: 75%;
                    max-width: rem(150);
                    margin: rem(10) 0
                }
                @include breakpoint($medium) {
                    margin: 0;
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    left: 30%;
                }
                @include breakpoint($large) {
                    left: 25%;
                }
            }
            &-addon {
                .service-status__active-item-content {
                    width: 100%;
                    max-width: 100%;
                    left: auto;
                    &:before {
                        display: none;
                    }
                    .title {
                        display: inline-block;
                        position: absolute;
                        top: 40%;
                        left: 28%;
                        transform: translate(0, -50%);
                        font-weight: 500;
                        @include breakpoint($medium) {
                            left: 25%;
                        }
                        @include breakpoint($large) {
                            left: 20%;
                        }
                    }
                }
                .icon {
                    display: inline-block;
                    width: rem(85);
                    height: rem(85);
                    margin: 0;
                }
            }
        }
        .status-circle {
            @include breakpoint($medium) {
                margin-top: rem(5);
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                z-index: 2;
            }
        }
    }
    &__title {
        margin-left: rem(20);
        font-weight: 500;
        position: relative;
        .auto-refill-badge {
            position: absolute;
            top: -6px;
            right: rem(20);
            background: #F2F4F4;
            border-left: 6px solid #47C684;
            padding: 5px 20px 5px 10px;
            border-radius: 3px;
            color: $tundora;
        }
        @include breakpoint($large) {
            margin-left: 0;
        }
    }
    .broadband-hyperlink {
        color: #2b9cd8;
        text-decoration-line: underline;
    }
}
