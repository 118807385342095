.handoff {
    background-color: #fff;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: rem(15);
        .xfinity-logo {
            display: inline-block;
            width: 74px;
            height: 25px;
            background-image: url('../../assets/img/xfinity-black.svg');
            background-repeat: no-repeat;
            background-size: contain;

            @include breakpoint($xlarge) {
                width:90px;
                height: 30.6px
            }
        }
        nav {
            a {
                text-decoration: none;
                text-transform: uppercase;
                font-size: rem(12);
                line-height: 1;
                &::before {
                    content: '< ';
                }
            }
        }
    }

    .main {
        padding: rem(120) rem(15);
    }

    &__intro {
        margin-bottom: rem(80);
        text-align: center;
    }

    &__links {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: rem(80);
        .create-id {
            margin-bottom: rem(30);
        }
        .log-in {
            font-size: rem(12);
            text-transform: uppercase;
        }
    }

    &__accordion-content {
        padding: rem(40) 0;
        .step {
            display: flex;
            flex-direction: column;
            font-size: rem(16);
            font-weight: 200;
            line-height: 1.5;
            a {
                font-size: rem(16);
                font-weight: 200;
                line-height: 1.5;
            }
            h2 {
                margin-bottom: rem(24);
                padding: 0;
                font-size: rem(24);
                line-height: 1.3;
                font-weight: 300;
                strong {
                    font-weight: 400;
                }
            }
            p {
                font-size: rem(16);
                font-weight: 200;
                line-height: 1.5;
                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
        .base {
            padding-top: rem(60);
        }
        .notification-banner {
            margin: 0 rem(-20) rem(40);
            left: 0;
            right: 0;
            position: relative;
            width: auto;
            .main__width-constraint {
                max-width: 100%;
            }
            @include breakpoint($medium) {
                margin: 0 0 rem(40);
            }
        }
    }

    .accordion__panel {
        display: flex;
        flex-direction: column;
        font-size: rem(16);
        font-weight: 200;
        line-height: 1.5;
        a {
            font-size: rem(16);
            font-weight: 200;
            line-height: 1.5;
        }
        h2 {
            margin-bottom: rem(24);
            padding: 0;
            font-size: rem(24);
            line-height: 1.3;
            font-weight: 300;
            strong {
                font-weight: 400;
            }
        }
        p {
            font-size: rem(16);
            font-weight: 200;
            line-height: 1.5;
            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }

}
