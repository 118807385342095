.move {

    /* confirmation animation */
    @keyframes grow-circle {
        0%{
            height: 10rem;
            width: 10rem;
        }
        80%{
            height: 14rem;
            width: 14rem;
            opacity:1;
        }
        100%{
            height: 14rem;
            width: 14rem;
            opacity:1;
        }
    }
    
    

    &--now{
        text-align: center;
        position: relative;
        padding-top:3rem;
        -webkit-user-select: none;  /* Chrome all / Safari all */
        -moz-user-select: none;     /* Firefox all */
        -ms-user-select: none;      /* IE 10+ */
        -o-user-select: none;
        user-select: none;
        .what-next {
            text-align: left;
            padding: rem(20);
        }
    }

    &--finalize{
        text-align:left;
    }

    &--disclaimer{
        margin: 3rem 0;
        font-weight: 400;
    }

    .page__title{
        margin:2rem 0;
    }
    .confirm-button--container{
        position: relative;
        height: 14rem;    
        text-align: center;
        font-size: 0.75rem;
        font-weight: 500;
        margin: 1.25rem 0;
        height:16rem;
    }
    .back-button{
        position: absolute;
        left: 0;
        padding-top:1rem;
        cursor: pointer;

    }
    .alert-section {
      margin: 0 1.25rem rem(30);

        &__row{
            font-size: rem(12);
            position:relative;
            padding: 1rem 0 1rem 3rem;
            text-align: left;
        }
        &__warning-sign{
            background-image: url('../../assets/img/icon-alert.svg');
            position: absolute;
            top: 50%;
            transform: translate(-3rem, -50%);
            height: 30px;
            width: 30px;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    .check-mark{
        background-image: url('../../assets/img/icon-check.svg');
        height: 100px;
        width: 100px;
        background-repeat: no-repeat;
        margin: 0 auto;
        border: #65D0A7 4px solid;
        border-radius: 100px;
        background-position: center;
        background-size: 50px;
    }

    .status-row .button{
        padding-left:0;
        text-align:left;
    }
    


    &__move-schedule--footer{
        position: fixed;
        bottom: 0;
        z-index: 20;
        width: 100%;
        padding: 15px;
        background: white;
        display: flex;
        border-top:2px solid #CCC;
        justify-content: space-between;
        button{
            margin: 0 10px 0 0;
        }
        .status{
            &--title{
                font-weight:700;
            }
            &--date{
                font-size: 18px;
            }
        }
    }

    &__refill-label{
        font-weight:700;
        margin-left: rem(20);
        @include breakpoint($large) {
            margin-left: 0;
        }
        &:before{
            content:"";
            height:10px;
            width:10px;
            background-color:#4EA3DE;
            display: inline-block;
            margin-right: 10px;
        }
    }
    &__move-schedule--tooltip{
        transform: translate3d(0, -10%, 0);
        opacity: 1;
        overflow-y: scroll;
        position: fixed;
        bottom: 50%;
        z-index: 2;
        padding: 1.125rem;
        width: 25rem;
        max-height: 200px;
        text-align: left;
        line-height: 1.125rem;
        color: #303030;
        background: #f2f4f5;
    }

    .service-section{
   
        text-align: left;
        position:relative;
        background:white;
        margin: 0 -0.9375rem;
        padding: 0 0.9375rem;
        &__row{
            display: flex;
            text-align: left;
            justify-content: space-between;
        }
        .button--link{
            flex-shrink:0;
            align-self: flex-start;
            text-transform:uppercase;
        }
        .address{
            padding-right:30px;
        }
        &__footer{
            text-align:center;
            width:100%;
        }
    }
    .service-status{
        margin: 0 -0.9375rem;
    }

    .fullbleed-offsets{
        padding: 20px 0.9375rem;
        margin: 20px 0.9375rem;
    }



    /* Cal Overrides */
    .Cal__Container__root{
        width:100% !important;
        font:inherit !important;

    }
    .Cal__Header__root{
        background: transparent !important;
        padding: 0;
    }
    .Cal__Header__range .Cal__Header__wrapper:first-child:before, .Cal__Header__range .Cal__Header__wrapper:first-child:after{
        display:none;
    }
    .Cal__Header__range .Cal__Header__wrapper:last-child {
        display: none;
    }
    .Cal__Header__dateWrapper.Cal__Header__year, .Cal__Header__range .Cal__Header__dateWrapper{
        color:black;
    }
    .Cal__Day__range.Cal__Day__selected.Cal__Day__start .Cal__Day__selection {
        border-radius: 15px;
    }
    .Cal__Month__rows {
        background: white;
    }
    .Cal__Month__row.Cal__Month__partial:first-child li:first-child {
        box-shadow: none;
    }

    .Cal__Month__row:first-child li {
        box-shadow: none;
    }
    .Cal__Month__row:nth-child(2) {
        box-shadow: none;
    }

    .Cal__Weekdays__root {
        background-color:white !important;
        color:black !important;
        text-transform:uppercase;
        border-bottom:2px solid #CCC;
    }
    .Cal__Day__root.Cal__Day__selected{
        color: #439dcf !important;
    }
    .Cal__Day__root.Cal__Day__selected .Cal__Day__selection .Cal__Day__day {
        top: -2px;
    }
    .Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection{
        border:none;
    }
    .Cal__Day__selection .Cal__Day__day{
        background: #439dcf;
        height: 100%;
        width: 100%;
        position: absolute !important;
        left: 0;
        top: 0 !important;
        color: black;
        border-radius: 10px;
    }
    .Cal__Day__root .Cal__Day__today .Cal__Day__enabled{
        color: white !important;
    }
    .Cal__Header__dateWrapper.Cal__Header__active {
        color: black;
    }

    .move-schedule-modal{
        background:red;
    }

    &__title {
        padding: rem(10) rem(20);
        font-size: rem(18);
        font-weight: 600;
    }

    &__month {
        padding: rem(5) 0 0 rem(20);
        font-size: rem(26);
        font-weight: 600;
    }
    &__year {
        font-weight: 300;
        margin-left: rem(5);
    }

}

.move-flow {
    .card {
        margin: 0;
    }
}
