.order-price-summary-table { //table
    // font-weight: bold;
    margin-left: auto;

    &__label { //td
        text-align: right;
        padding-right: 5px;
        font-weight: 200;
        font-size: rem(16);
    }
    &__price { //td
        text-align: right;
        padding: 0 0 0 5px;
        font-weight: 600;
    }
    
    hr { margin: 0; }
}