    .status-row{
        padding:20px 0;
     .round {
        position: relative;
      }
      .title{
          font-weight: 800;
      }
      .text{
          padding-left:50px;
      }
      button{
        font-weight: 800;
      }

      &--success{
        .round .check {
            background-color: #fff;
            border: 1px solid $merchant-prepaid-green;
            border-radius: 50%;
            cursor: pointer;
            height: 32px;
            left: 0;
            position: absolute;
            top: 0;
            width: 32px;
        }
        
        .round .check:after {
            border: 1px solid $merchant-prepaid-green;
            border-top: none;
            border-right: none;
            content: "";
            height: 9px;
            left: 9px;
            position: absolute;
            top: 9px;
            transform: rotate(-45deg);
            width: 14px;
        }
        
        
        .round .check {
            background-color:#FFF;
            border-color: $merchant-prepaid-green;
        }
      }
    }