.device-details {
  margin: rem(30) auto;
  padding: rem(20);
  max-width: rem(1072);
  background-color: $white;
  &-item {
    &:first-of-type {
      border-top: 1px solid $alto;
    }
    border-bottom: 1px solid $alto;
    padding: rem(20) 0;
    font-size: rem(16);
    &-label {
      font-size: rem(12);
      font-weight: 600;
    }
  }
}
