.promo-banner {
    $bg-accent-width: 90%;
    $bg-accent-width-medium: 68%;
    position: relative;
    display: grid;
    grid-template-columns: 1fr minmax(auto, rem($content-width)) 1fr;
    padding: $site-padding rem(10);
    background-color: $white;
    background-image: linear-gradient(110deg, $xfinity-red $bg-accent-width, $white $bg-accent-width + .1%);
    @include breakpoint($medium) {
		background-image: linear-gradient(110deg, $xfinity-red $bg-accent-width-medium, $white $bg-accent-width-medium + .1%);
	}
    color: $white;
    &::before {
        content: "";
        background-color: $xfinity-red;
        grid-column: 1;
        grid-row: 1;
    }
    &::after {
        content: "";
        background-color: $white;
        grid-column: 3;
        grid-row: 1;
    }
    &__content {
        grid-column: 2;
        text-decoration: none;
        color: white;
        &__copy {
            max-width: $bg-accent-width;
            @include breakpoint($medium) {
                max-width: $bg-accent-width-medium;
            }
            h3 {
                font-weight: 700;
            }
            p {
                margin: 0;
                font-weight: 500;
            }
        }
        &__link {
            position: absolute;
            left: calc(#{$bg-accent-width} - 10px);
            top: calc(50% - 20px);
            @include breakpoint($medium) {
                left: calc(#{$bg-accent-width-medium} - 15px);
            }
            &__icon {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 40px;
                width: 40px;
                border-radius: 50%;
                background-color: $porcelain;
                .line {
                    height: 2px;
                    width: 16px;
                    display: block;
                    transition: all 0.3s ease-in-out;
                    border-radius: 2px;
                    background-color: $black;
                    &:nth-child(1) {
                        transform: translateX(0px) translateY(-4px) rotate(45deg);
                    }
                    &:nth-child(2) {
                        transform: translateX(0px) translateY(4px) rotate(-45deg);
                    }
                }
            }
        }
    }
}