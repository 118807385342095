//Utility classes

.width-49 { width: 49%; }
.width-50 { width: 50%; }
.width-100 { width: 100%; }
@include breakpoint($medium) {
  .width-49--medium-up { width: 49%; }
}
@include breakpoint($large) {
  .width-100--large-up { width: 100%; }
}

// .margin-top-10 { margin-top: rem(10); }
// .margin-top-40 { margin-top: rem(40); }
// .margin-bottom-10 { margin-bottom: rem(10); }
// .margin-bottom-20 { margin-bottom: rem(20); }
// .margin-bottom-30 { margin-bottom: rem(30); }
// .margin-left-10 { margin-left: rem(10); }
// .margin-left-auto { margin-left: auto; }
// .margin-right-10 { margin-right: rem(10); }
.no-margin-bottom { margin-bottom: 0 !important; }
.no-margin-top { margin-top: 0 !important; }
.vertical-margin-block { margin-top: spacing('xs'); margin-bottom: spacing('xs'); }
// .vertical-margin-block--30 { margin-top: rem(30); margin-bottom: rem(30); }

// .no-padding { padding: 0 }
// .no-padding-bottom { padding-bottom: 0; }
.padding-left-10 { padding-left: 10px; }
// .padding-left-input-pad { padding-left: 52px; } //left padding to match the padding given to inputs
// .padding-right-0 { padding-right: 0; }
// .padding-right-10 { padding-right: 10px; }
// .padding-right-30 { padding-right: 30px; }

// .text-left { text-align: left; }
.text-right { text-align: right; }
.text-center { text-align: center }
// .text-bold { font-weight: bold; }
// @include breakpoint($medium) {
//    .text-right--medium-up { text-align: right; }
//    .text-left--medium-up { text-align: left; }
// }

.float-right{
  float:right;
}

.bg-white { background-color: white; }

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}


.vh {
  position: absolute!important;
  display: block;
  visibility: visible;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
}

.flex {
  $block: &;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  .flex-item {
    flex: 0 1 auto;
  }

  &--v-center { align-items: center; }
  &--justify { justify-content: space-between; }

  &--50-50 {
    .flex-item {
      width: 100%;
    }
      @each $b, $p in $breakpoints {
          &[data-flex-start="#{$b}"] {
              @include breakpoint(map-get($breakpoints, $b)) {
                  justify-content: space-between;

                  .flex-item {
                      width: calc(50% - (#{spacing('xxs')}));
                      flex: 0 1 auto;
                      // flex-basis: calc(50% - (#{spacing('xxs')}));
                  }
              }
          }
          &[data-flex-end="#{$b}"] {
              @include breakpoint(map-get($breakpoints, $b), map-get($breakpoints-max, $b)) {
                  // justify-content: space-between;

                  .flex-item {
                      width: 100%;
                  }
              }
          }
      }

  }
  &--30-30-30 {
    .flex-item {
      width: 100%;
    }
    @each $b, $p in $breakpoints {
      &[data-flex-start="#{$b}"] {
        @include breakpoint(map-get($breakpoints, $b)) {
          justify-content: space-between;

          .flex-item {
            width: calc(33% - (#{spacing('xxs')}));
            flex: 0 1 auto;
            // flex-basis: calc(50% - (#{spacing('xxs')}));
          }
        }
      }
      &[data-flex-end="#{$b}"] {
        @include breakpoint(map-get($breakpoints, $b), map-get($breakpoints-max, $b)) {
          // justify-content: space-between;

          .flex-item {
            width: 100%;
          }
        }
      }
    }

  }
}

@mixin flex-container-wrap-items($flex-basis, $max-expected-width: 2000px) {
    display: flex;
    flex-wrap: wrap;

    > * {
        max-width: 100%;
        flex-grow: 1;
        flex-basis: $flex-basis;
    }

    $multiplier: 1;
    $current-width: 0px;

    @while $current-width < $max-expected-width {
        $current-width: $current-width + $flex-basis;
        $multiplier: $multiplier + 1;

        &[min-width~="#{$flex-basis * $multiplier}"] > * {
            max-width: percentage(1/$multiplier);
        }
    }
}

.align-self-start    { align-self: start !important; }

.display-large {
  display: none;
  @include breakpoint ($large) {
      display: block;
  }
}
.display-small-medium {
    display: block;
    @include breakpoint ($large) {
        display: none;
    }
}
