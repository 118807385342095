// Accordion component
.accordion {
	$block: &;
  position: relative;

	// border-top: 1px solid $alto;
	// border-bottom: 1px solid $alto;
	// margin: spacing('sm', -1) 0;

	// @include transition(height 0.3s ease-in-out);

	@include breakpoint($large) {
		margin: 0;
	}

	.expand-enter {
    max-height: 0;
    transition: all 800ms ease-in;
  }
  
  .expand-enter-active {
    max-height: 1000px;
    transition: all 800ms ease-in;
  }

  .expand-exit-done {
    max-height: 0;
    transition: all 400ms ease-out;
  }

    &__title {
        color: $cod-gray;
        cursor: default;
      font-weight: 400;
      border-bottom: 1px solid #d1d1d1;


    }
    &__title,
    &__tab {
        position: relative;
        margin: 0;
        padding: rem(15) rem(40) rem(15) rem(20);
        font-size: rem(18);
        line-height: rem(24);
        font-weight: 300;
		cursor: pointer;
    @include breakpoint($medium) {
      font-size: 24px;
      font-weight:100;
    }

	}

  &__faq-group {
    background-color: $white;
    margin: spacing('sm') rem(-15);
    .dashboard & {
      margin: spacing('sm') 0;
    }
    .buyflow & {
      margin: spacing('sm') rem(-7);
      
      @include breakpoint($medium) {
        margin: spacing('sm') 0;
      }
    }

    @include breakpoint($medium) {
      margin: spacing('sm') 0;
    }
    &.bg-gray {
      background-color: $alternate-gray;
    }

    @include breakpoint($medium) {
      margin: spacing('sm') 0;
    }
    h2 {
      padding: rem(20);
      margin-bottom: rem(10);
    }

    .accordion__panel {
      padding: 0 rem(20);
    }

    #{$block}__tab {
      border-left: 6px solid $porcelain;
      border-color: $porcelain;
      font-size: rem(14);
      font-weight: 200;
      line-height: rem(22);
      padding: spacing('xs') 0;
      padding-left: spacing('xs', 0.5);
      padding-right: rem(50);
      transition: border-color 0.2s ease-in-out;
      @include breakpoint ($large) {
        font-size: rem(18);
        font-weight: 300;
        line-height: rem(27);
      }
      &:focus {
        outline: 0;
        border-color: $cta-blue;
      }
    }
  }
  &__link {
    font-size: rem(14);
    font-weight: bold;
    position: absolute;
    right: 18px;
    text-decoration: none;
    top: 18px;
  }

    &--text {
        .accordion {
          &__item {
            border: none;
          }
            &__tab {

                max-width: rem(150);
                margin-left: auto;
                margin-right: auto;
                padding: rem(10) 0;
                font-weight: 300;
                color: $cta-blue;
                font-size: rem(12);
                text-decoration: underline;
                .accordion__icon {
                  right: 0;
                }
                &[aria-expanded="true"] {
                    .accordion__icon {
                        .line {
                            &:nth-child(1) {
                                transform: translateX(4px) translateY(4px) rotate(-45deg);
                            }
                            &:nth-child(2) {
                                transform: translateX(8px) translateY(-2px) rotate(45deg);
                            }
                        }
                    }
                }
            }
            &__panel {

                text-align: left;
                padding: 0;
                margin-top: 0;
                font-size: rem(12);
                border-top: none;
                overflow: scroll;
              @include breakpoint($large) {
                overflow: hidden;
              }
              &.closed {
              }
            }
            &__icon {
                .line {
                    width: 6px;
                    background-color: $cta-blue;

                    &:nth-child(1) {
                        transform: translateX(4px) translateY(4px) rotate(45deg);
                    }
                    &:nth-child(2) {
                        transform: translateX(8px) translateY(-2px) rotate(-45deg);
                    }
                }
            }
        }
    }


	&__tab {

	}
    &__panel {
      overflow: hidden;
      max-height: 1800px;
      margin: 0;
      border-top: 1px solid $alto;
		  padding:0;
	  	width: 100%;

      p {
        font-size: rem(16);
        font-weight: 200;
        line-height: 1.5;
        margin: rem(20) 0;
        &:first-child {
          margin-top: rem(35);
        }
        &:last-child {
          margin-bottom: rem(35);
        }
      }

      .faq-video {
        position: relative;
        vertical-align: middle;
        padding-top: rem(20);
        img {
          display: inline;
        }
        .button--link--large {
          margin-left: rem(20);
          display: inline-block;
          font-size: rem(18);
          font-weight: 400;
          text-align: left;
          max-width: rem(200);
          margin-top: rem(10);
          text-transform: uppercase;
          text-decoration: none;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          &:hover, &:focus {
            text-decoration: underline;
          }
        }
      }
    }
    &__icon {
        position: absolute;
        content: "";
        top: 50%;
        transform: translate(-50%, -50%);
        right: 20px;
        transition: 0.2s all;
        .line {
            width: 15px;
            display: block;
            margin: 5px auto;
            transition: all 0.3s ease-in-out;
			border-radius: 2px;
			height: 1px;
			background-color: $cta-sky;
			&:nth-child(1) {
				transform: translateX(0px) translateY(4px) rotate(45deg);
			}
			&:nth-child(2) {
				transform: translateX(11px) translateY(-2px) rotate(-45deg);
			}
			@at-root #{$block}__tab[aria-expanded="true"] & {
                &:nth-child(1) {
                    transform: translateX(0px) translateY(4px) rotate(-45deg);
                }
                &:nth-child(2) {
                    transform: translateX(11px) translateY(-3px) rotate(45deg);
                }
            }
        }

		// @at-root #{$block}__tab[aria-expanded="true"] & {
		// 	transform-origin: center;
		// 	transform: rotate(180deg);
		// }
    }

	&__heading {
		margin-bottom: spacing('sm');
        font-size: rem(18);
        line-height: rem(24);
        font-weight: 300;
	}

	&__content {
		padding: spacing('sm', 2) 0;
    &.null-top-padding {
      padding: 0 0 rem(60);
    }
		&--shop {

		}
        &-heading {
            margin-bottom: spacing('sm');
            position: relative;
            z-index: 1;
        }
	}

	&--with-input {
		// @extend %card;
		// Replicated "Card" styling.. Not sure if this is the best way to handle though
		background: $white;
		margin-left: spacing('sm', -0.5);
		margin-right: spacing('sm', -0.5);

		@include breakpoint($large) {
			margin: 0;
		}

		.checkbox {
			margin: 0;
		}

		#{$block}__content {
			background: $cod-gray;
			color: $white;
			padding: 0;
      position: relative;
      overflow: hidden;

            &::after {
                display: block;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, #000 100%);
                margin-top: -200px;
                height: 200px;
                width: 100%;
                content: '';
                position: relative;
                z-index: 0;
            }
		}

		#{$block}__heading {
			padding: 0 spacing('sm', 0.5);
			padding-top: spacing('xs');
		}

		#{$block}__item {

		}
		#{$block}__tab {
			border-left: 6px solid $porcelain;
			border-color: $porcelain;
			padding: spacing('xs') 0;
			padding-left: spacing('xs', 0.5);
			transition: border-color 0.2s ease-in-out;

			&:focus {
				outline: 0;
				border-color: $cta-blue;
			}
		}
    &.dashboard {
      margin: rem(20) 0 0;
      .accordion__tab {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
	}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .accordion {
            &__panel {
                width: 100%!important;
                visibility: visible!important;
                position: relative!important;

            }
        }
    .channel-pack-background-image {
        transform: none!important;
    }
}
