.acp {
    &_teaser {
        margin: rem(40) rem(15);
        @include breakpoint($medium) {
            margin: rem(60) 0;
        }
        .promo-card {
            position: relative;
            &_wrapper {
                background-color: white;
                @include breakpoint($medium) {
                    display: flex;
                }
            }
            &_img-container {
                @include breakpoint($medium) {
                    display: inline-block;
                    width: 50%;
                }
            }
            &_text-container {
                @include breakpoint($medium) {
                    display: inline-block;
                    width: 50%;
                    text-align: center;
                    padding: rem(25);
                }
                .dashboard & {
                  background-color: $ACPurple;
                  color: $white;

                  @include breakpoint($medium) {
                    background-color: $white;
                    color: $cod-gray;
                    display: block;
                  }
                }
                .acp-card {
                    &-body {
                        font-size: rem(14);
                        line-height: rem(22);
                        font-weight: 400;
                        margin-bottom: rem(10);
                        @include breakpoint($medium) {
                            font-size: rem(32);
                            line-height: rem(42);
                            font-weight: 700;
                            margin-bottom: rem(20);
                            position: relative;
                            bottom: 0;
                        }
                        .dashboard & {
                          display: none;

                          @include breakpoint($medium) {
                            display: block;
                          }
                        }
                    }
                    &-disclaimer {
                        font-size: rem(12);
                        line-height: rem(18);
                        font-style: italic;
                        margin-bottom: rem(10);
                        @include breakpoint($medium) {
                            font-size: rem(24);
                            line-height: rem(32);
                            font-style: normal;
                            margin-bottom: rem(20);
                        }
                        .dashboard & {
                          text-align: center;
                        }
                    }
                    &-link-text {
                        color: $cta-blue;
                        text-transform: uppercase;
                        font-size: rem(14);
                        line-height: rem(18);
                        @include breakpoint($medium) {
                            font-size: rem(24);
                            line-height: rem(32);
                            
                        }
                        .dashboard & {
                          background-color: $white;
                          border-radius: 40px;
                          color: $cod-gray;
                          height: 36px;
                          margin: 0 auto;
                          max-width: 212px;
                          padding: 9px;
                          text-align: center;

                          @include breakpoint($medium) {
                            background-color: transparent;
                            color: $blue-link;
                          }
                        }
                    }
                }
            }
        }
    }
    &_header {
        font-size: rem(12);
        font-weight: 500;
        @include breakpoint($medium) {
            margin-bottom: rem(20);
            font-size: rem(16);
            margin-left: 0;
        }

        .dashboard & {
          display: none;
        }
    }
    &-modal {
        padding: rem(50) 0;
        .acp_teaser {
            margin: 0;
        }
        @include breakpoint($medium) {
            padding: rem(50) rem(20);
        }
        &-body {
            text-align: center;
            font-size: rem(14);
            line-height: rem(22);
        }
        &-link {
            display: block;
            margin: rem(20) auto;
            text-align: center;
        }
        &-opt-out {
            display: block;
            color: black;
            background: none;
            border: none;
            text-decoration: underline;
            padding: 0;
        }
    }
    &-tribal {
        &-header {
            display: block;
            font-size: rem(12);
            line-height: rem(20);
            font-weight: 400;
        }
        &-disclaimer {
            display: block;
            max-width: 70%;
            display: block;
            font-size: rem(10);
            line-height: rem(14);
            font-weight: 400;
        }
    }
}