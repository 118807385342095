.package-channels {
	&__list{
        @include breakpoint(max-width $tiny) {
            margin: 0 rem(-5) 0 rem(-5);
    }
		margin: 0 rem(-5) 0 rem(-5);
		padding: 0;
		list-style: none;
		width: 100%;
        display: flex;
        flex-wrap: wrap;
        @include breakpoint($medium) {
            margin: 0;
        }
	}

	&__item {
    position: relative;

        @include breakpoint(max-width $tiny) {
            min-width: 58px;
            margin: 0 rem(6) rem(10) rem(6);
        }
        @include flex-wrap-fix(60px);
        margin: 0 rem(5) rem(10) rem(5);
        height: auto;
		background: $white;
        margin-bottom: spacing('xs', 0.5);
        @include breakpoint($medium) {
            margin: 0 spacing('xs', 0.5) spacing('xs', 0.5) 0;
        }
        @include breakpoint($large) {
            min-width: 60px;
        }

		img, svg {
			width: 100%;
			height: auto;
			margin: auto;
		}

    &.devices {
      img {
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

		.package-channels--collapsed & {
            @include breakpoint(max-width $tiny) {
                &:nth-of-type(n+5) {
                    display: none;
                }
            }
			@include breakpoint(max-width $max-small) {
				&:nth-of-type(n+6) {
					display: none;
				}
			}
			@include breakpoint(max-width $max-medium) {
				&:nth-of-type(n+9) {
					//display: none;
				}
			}
			@include breakpoint($large) {

			}

		}
	}

    &--collapsed {
        .package-channels__view-all {
            svg, img {
                transform: rotate(0);
            }
        }
      .basic-pack {
        @include breakpoint(max-width $max-small) {
          display: none;
        }
      }
    }

	&__view-all {
		display: block;
        margin-top: rem(10);
        margin-bottom: rem(20);
        z-index: 1;
        color: $cta-sky;

        @include breakpoint($medium) {
            display: none;
        }

        svg {
            position: relative;
            top: 3px;
            margin-left: 5px;
            transform: rotate(180deg);
        }
        img {
            position: relative;
            height: 12px;
            margin-left: 5px;
        }
	}

}
.channel-pack {
    padding: spacing('sm') spacing('xs');
    z-index: 1;
    .modal-item {
        .button--link--underline {
            z-index: 1;
            margin-top: rem(30);
            position: relative;
            left: 50%;
            transform: translate(-50%, 0);
            color: $white;
        }
    }
    &-background-image {
        position: absolute;
        display: block;
        top: 0;
        left: 50%;
        z-index: 0;
        max-width: unset;
        transform:translate(-50%,0);
        overflow: hidden;
    }
}
