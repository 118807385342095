.image-sliding-carousel {
    $block: &;
    $spacing-mobile: rem(14);
    $spacing-desktop: rem(7);
    $dots-mobile: 54px;
    $dots-desktop: 38px;

    padding-top: rem(10);
    padding-bottom: rem(40);


    *:focus {
        outline-color: #000;
    }

    &__count {
        padding: 0 $spacing-mobile;
        text-transform: uppercase;
        font-weight: 600;
        font-size: rem(12);
        margin-top: rem(20);
        margin-bottom: rem(20);
        color: $tundora;
      @include breakpoint($large) {
        padding: 0;
      }

        span {
            font-weight: 400;
        }
    }

    &__playpause {
        padding: 0 $spacing-mobile;
        position: relative;
        bottom: -15px;
        z-index: 10;

        @include breakpoint($medium) {
            padding: 0 $spacing-desktop;
            margin: 0 auto;
            bottom: -10px
        }

        .play-pause {
            position: relative;
            display: inline-block;

            &__btn {
                display: block;
                border: 1px solid #191919;
                border-radius: 100%;
                width: 22px;
                height: 22px;
                margin: 0;
                cursor: pointer;
                position: relative;

                transition: 180ms border ease-in-out;

                .play-pause__icon {
                    border: 0;
                    opacity: 1;
                    background: transparent;
                    display: block;
                    box-sizing: border-box;
                    margin: 0;
                    width: 0;
                    height: 8px;
                    border-color: transparent transparent transparent #191919;
                    transition: 180ms border ease-in-out;
                    will-change: border-width;
                    // paused state
                    border-style: double;
                    border-width: 0 0 0 4px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1001;
                }
                // @include breakpoint($medium){
                // 	&:hover {
                // 		border-color: $black;
                // 		.play-pause__icon {
                // 			border-color: transparent transparent transparent $black;
                // 		}
                // 	}
                // }
            }

            input {
                @extend .vh;

                &[disabled] + .play-pause__btn {
                    opacity: 0;
                }

                &:checked + .play-pause__btn .play-pause__icon {
                    // play state
                    border-style: solid;
                    border-width: 4px 0 4px 6px;
                    left: calc(50% + 1px);
                }

                &:focus + label {
                    border-color: $black;
                    outline: -webkit-focus-ring-color auto 5px;
                    outline-color: $black;

                    .play-pause__icon {
                        border-color: transparent transparent transparent $black;
                    }
                }
            }
        }
    }

    &__wrapper {
        position: relative;
        box-shadow: 0px 2px 8px -2px rgba(0,0,0,0.33);
        margin: rem(4) 0;
    }

    &__image {
        position: relative;

        img {
            width: 100%;
        }
    }

    &__list {
        margin-bottom: $dots-mobile * 1.5;

        @include breakpoint($medium) {
            margin-bottom: $dots-desktop * 2;
        }
    }

    &__item {
        z-index: 1;
        overflow: visible;
        width: calc(89vw - #{$spacing-mobile});
        padding: 0 0 0 $spacing-mobile;
        position: relative;

        @include breakpoint($medium) {
            padding: 0 $spacing-desktop;
        }

        @include breakpoint($large) {
            width: 50vw;
        }

        &--image-and-copy {
            height: 100%;

            #{$block}__wrapper {
                background: $white-haze;
                height: calc(100% - #{rem(8)});

                @include breakpoint($medium) {
                    background: none;
                    height: auto;
                }
            }
        }
    }

    &__content {
        position: relative;
        padding: rem(35) rem(20);
        color: $white;
    }

    &__legal-content {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        padding: rem(15);
        background: $disclaimer-background;

        &,
        p {
            color: $white;
        }
    }

    &__blurb {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
      &-text {
        padding: rem(15);
        background: $white;
      }
      &-title {
        font-weight: 500;
        margin-bottom: rem(7);
      }
      &-top-flag {
        position: relative;
        display: inline-block;
        color: #fff;
        background-color: #191919;
        padding: rem(3) rem(20);
        line-height: rem(19);
        font-size: rem(12);
        font-weight: 600;
        z-index: 1;
        min-inline-size: 20%;
        white-space: nowrap;
        max-width: 200px;
        .text-limit {
            width: rem(100);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
            @include breakpoint($tiny) {
                width: rem(140);

            }
            @include breakpoint($small) {

                width: rem(130);
            }
            @include breakpoint($medium) {

                width: rem(150);
            }
            
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #000;
          -webkit-transform-origin: 100% 0;
          -ms-transform-origin: 100% 0;
          transform-origin: 100% 0;
          -webkit-transform: skew(25deg);
          -ms-transform: skew(25deg);
          transform: skew(25deg);
          z-index: -1;
        }
      }

        @include breakpoint($medium) {
            min-height: 0;
        }

        &,
        p {
            color: $mine-shaft;
            font-weight: 200;
            font-size: rem(14);
            line-height: rem(18);

            @include breakpoint($medium) {
                font-size: rem(16);
                line-height: rem(20);
            }
        }
    }

    &__cta {
        position: absolute;
        bottom: rem(20);

        &, a {
            font-weight: 500;
            text-transform: uppercase;
            text-decoration: none;
            font-size: rem(14);

            @include breakpoint($medium) {
                font-size: rem(16);
            }
        }

        @include breakpoint($medium) {
            width: rem(150);
            display: inline-block;
            text-align: right;
            position: relative;
            bottom: auto;
        }
    }

    &__legal {
        background: rgba($black, 0.5);
        width: 100%;
        position: absolute;
        bottom: 0;

        .text-link {
            color: $white;
            margin: 0;
            padding: rem(5) rem(8);
        }
    }


    .slider__nav {
        visibility: hidden;
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: rem(50);
        z-index: 1;
        width: 70px;
        height: 70px;
        padding: 0;
        font-size: 0;
        line-height: 0;
        // color: transparent;
        border: none;
        outline: none;
        background: rgba($black, 0.5);
        transform: translateY(-50%);
        transition: background 180ms ease-in-out;

        &--next {
            right: rem(50);
            left: auto;
            &::before {
                border-style: solid;
                border-width: 1px 1px 0 0;
                border-color: $white;
                content: '';
                display: inline-block;
                height: 20px;
                width: 20px;
                top: 25px;
                right: 30px;
                position: absolute;
                transform: rotate(45deg);
            }
        }
        &--prev {
            &::before {
                border-style: solid;
                border-width: 1px 1px 0 0;
                border-color: $white;
                content: '';
                display: inline-block;
                height: 20px;
                width: 20px;
                top: 25px;
                left: 30px;
                position: absolute;
                transform: rotate(-130deg);
            }
        }

        &:hover {
            background: rgba($black, 1);
        }

        @include breakpoint($large) {
            visibility: visible;
        }
    }

    .slider__icon {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 30px;
        height: 30px;
        margin: auto;
        fill: $white;
    }

    .slick-dots {
        position: absolute;
        left: 0;
        right: 0;
        bottom: $dots-mobile * -1;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        // max-width:rem(250);
        margin: 0 auto;
        z-index: 9;

        @include breakpoint($medium) {
            bottom: $dots-desktop * -1;
        }

        li {
            position: relative;
            display: inline-block;
            width: 36px;
            height: 36px;
            margin: 0 rem(5);
            // padding: rem(10) 0;
            cursor: pointer;

            @include breakpoint($medium) {
                width: 24px;
                height: 24px;
            }
        }

        li button {
            font-size: 0;
            line-height: 0;
            display: block;
            width: 12px;
            height: 12px;
            // padding: rem(10) 0;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background: transparent;

            @include breakpoint($medium) {
                width: 12px;
                height: 12px;
            }
        }

        li button:hover,
        li button:focus {
            outline: none;
        }

        li button:hover:before,
        li button:focus:before {
            opacity: 0.8;
        }

        li button:before {
            font-size: rem(12);
            line-height: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 8px;
            padding: rem(10) 0;
            content: "\2022";
            text-align: center;
            // color: $slick-dot-arrow-active;
            color: rgba($black, 0.2);
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            @include breakpoint($medium) {
                font-size: rem(12);
            }
        }

        li.slick-active button:before {
            color: rgba($black, 1);
        }
    }

    .slick-list {
        // max-width:80vw;
    }
}
/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

