@keyframes size {
    0% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    100% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}
.header {
    background-color: black;
    color: white;
    padding: 0;
    min-height: rem(60);
    position: relative;

    @include breakpoint($large) {
        padding: rem(10) 0 0 0;
    }
  header {
    background-color: black;
    position: relative;
    @include breakpoint($large) {
      background-color: transparent;
      padding: 0;
    }
  }

  .disabled-link {
    cursor: not-allowed;
    pointer-events: none;
  }

    &__width-constraint {
        margin: 0 auto;
        display: table;
        width: 100%;
        padding: $site-padding rem(10);
        @include breakpoint($medium) {
            padding: 20px rem(35) rem(20);
        }
      @include breakpoint($large) {
        padding: rem(10) rem(20);
      }
        @include breakpoint($xlarge) {
            padding: 10px rem(130);
        }
    }

    &__top {
        background-color: black;
        position: relative;
        z-index: 22;
    }

    &__left {
        display: table-cell;
        vertical-align: middle;
    }

    &__right {
        display: table-cell;
        vertical-align: middle;
        text-align: right;
        position: relative;
    }

    .xfinity-logo {
        display: inline-block;
        position: relative;
        top: 3px;
        left: 3px;
        width: 74px;
        height: 25px;
        background-image: url("../../assets/img/xfinity.svg");
        background-repeat: no-repeat;
        background-size: contain;
        @include breakpoint($large) {
            top: auto;
            left: auto;
        }

        @include breakpoint($xlarge) {
            width:90px;
            height: 30.6px;
        }

        &.secondary {
            background-image: url("../../assets/img/prepaid.svg");
            margin-left: rem(20);
            position: relative;
            &::before {
                content: '|';
                font-size: rem(24);
                font-weight: 100;
                color: white;
                left: -15px;
                top: -5px;
                position: absolute;
            }
        }
    }
    .prepaid-logo{
        display: inline-block;
        position: relative;
        top: 3px;
        left: 3px;
        width: 74px;
        height: 25px;
        background-image: url("../../assets/img/prepaidlogo.svg");
        background-repeat: no-repeat;
        background-size: contain;
        @include breakpoint($large) {
            top: auto;
            left: auto;
        }

        @include breakpoint($xlarge) {
            width:90px;
            height: 30.6px;
        }

    }
    .logo__vertical-line{
        width:0px;
        height: 27px;
        border-left: 1px solid #eee;
        display: inline-block;
        vertical-align: top;
        margin: 0 8px;
    }
    &-language {
        &__list {
            margin: 0;
            padding: 0;
            list-style: none;
            font-size: 0;
            position: relative;
            top: 50%;
            transform: translate(0, -50%);
            &-desktop {
                display: none;
                @include breakpoint($large) {
                    display: inline-block;
                }
            }
        }

        &__item {
            font-size: 0;

            &:nth-child(2) {
                margin-left: rem(15);
                padding-left: rem(15);
                border-left: 1px solid $white;
            }
        }

        &__link {
            font-size: rem(12);
            text-decoration: none;
            color: $white;
        }
      &-switch {
        font-size: rem(12);
        text-decoration: none;
        color: $white;
        a {
          font-size: rem(12);
          text-decoration: none;
          color: $white;
        }
        &-logout {
            &::before {
                content: '|';
                padding: 0 rem(8);
            }
        }
      }
    }
    &__account-link {
        font-size: rem(14);
        margin-left: rem(25);
        text-decoration: none;
        color: $white;
        &-logout {
            display: inline-block;
            &:before {
                content: "";
                background-image: url("../../assets/img/icon-account-login.svg");
                content: "";
                display: inline-block;
                position: relative;
                top: 8px;
                margin-right: rem(8);
                width: 25px;
                height: 25px;
            }
        }
        &-desktop {
            display: none;
            position: relative;
            bottom: 10px;
            @include breakpoint($large) {
                display: inline;
            }
        }
        &-mobile {
            display: inline-block;
            font-size: rem(12);
            font-weight: 700;
            @include breakpoint($large) {
                display: none;
            }
        }
        .numberCircle {
            display: inline-block;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            padding: 2px;
            background: #FFA700;
            color: $black;
            text-align: center;
            margin: 0 rem(5);
            font-size: rem(10);
            animation: size 5s ease-in;
            -webkit-animation: size 0.2s ease-in;

            @include breakpoint($large) {
                font-size: rem(12);
            }
        }
    }
    &__bottom {
        background: rgba($white, 0.95);
        // border-top: 1px solid $main-nav-border;
        box-shadow: 0px 6px 6px -6px rgba(0,0,0,0.13);
        height: 59px;
        position: relative;
        z-index: 8;
        display: none;

        @include breakpoint($large) {
            height: 71px;
            display: block;
        }

        .wrapper {
            max-width: 100%;
            @include breakpoint($medium) {
                padding: 0 20px;
            }
            @include breakpoint($xlarge) {
                padding: 0 rem(130);
            }
        }
    }
}


.offcanvas-trigger {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    background: none;
    border: none;
    position: absolute;
    top: rem(25);
    right: rem(10);
    z-index: 20;
    &.dashboard {
        position: relative;
        top: auto;
        right: auto;
        margin-left: rem(10);
    }
  @include breakpoint($medium) {
    top: rem(30);
    right: rem(20);
  }
}

.is-off-canvas-open {
    overflow: hidden;
}

.js-off-canvas-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    //background: rgba(254, 254, 254, 0.25);
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
}

.js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible;
}

.js-off-canvas-overlay.is-closable {
    cursor: pointer;
}

.js-off-canvas-overlay.is-overlay-absolute {
    position: absolute;
}

.js-off-canvas-overlay.is-overlay-fixed {
    position: fixed;
}

.off-canvas-wrapper {
    position: relative;
    overflow: hidden;
}

.off-canvas {
    transition: transform 0.5s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: fixed;
    z-index: 21;
    background: $tundora;
    text-align: left;
}

[data-whatinput='mouse'] .off-canvas {
    outline: 0;
}

.off-canvas.is-transition-overlap {
    z-index: 10;
}

.off-canvas.is-transition-overlap.is-open {
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
}

.off-canvas.is-open {
    @include transform(translate(0, 60px));
}

.off-canvas-absolute {
    transition: transform 0.5s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    position: absolute;
    z-index: 1;
    background: #e6e6e6;
}

[data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0;
}

.off-canvas-absolute.is-transition-overlap {
    z-index: 10;
}

.off-canvas-absolute.is-transition-overlap.is-open {
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
}

.off-canvas-absolute.is-open {
    @include transform(translate(0, 0));
}


.position-top {
    @include transform(translateY(calc(-100vh)));

    top: 0px;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow-x: auto;
}

.position-top.is-open ~ .off-canvas-content {
    @include transform(translateY(calc(100vh - 159px)));
}

.position-top.is-transition-push::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " ";
}

.position-top.is-transition-overlap.is-open ~ .off-canvas-content {
    @include transform(none);
}

.off-canvas-content {
    transition: transform 0.5s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.offcanvas {
    background: $cod-gray;
    color: #fefefe;
    padding-bottom: rem(160);
    display: block;
    @include breakpoint($large) {
        display: none;
    }

    .account-btn {
        display: none;
    }
}

.offcanvas > .menu {
    margin: 0;
    padding: 0;
    list-style: none;
    margin: rem(20) 0;
}

.offcanvas .vertical.menu {
    border-top: 1px solid $tundora;
}

.off-canvas-menu__submenu {
    margin: rem(18) rem(-15) rem(-18) rem(-18);
    padding: 0;
    list-style: none;
    padding: rem(12) rem(30);
    background: $prepaid-text;
    border-top: 1px solid $tundora;
    border-bottom: 1px solid $tundora;
    display: none;
    &[aria-expanded="true"] {
        display: block;
    }

    .off-canvas-menu__item {
        border: 0;
        margin: 0;
    }

    .off-canvas-menu__link {
        padding: rem(8) 0;
        font-size: rem(16);
        font-weight: 200;
        border-bottom: none;

        &.active {
            border-left: 3px solid transparent;
            font-weight: 500;
        }
    }
}

.off-canvas-menu__item {
    border-bottom: 1px solid $tundora;

    &:last-child {
        border-bottom: 0;
    }

    &.refill-button, &.login-button {
        border-bottom: none;

        a {
            margin: 20px;
            border: 1px solid $tundora;
            border-radius: rem(3);
            text-transform: uppercase;
            padding: 20px;
            background: black;
            font-size: rem(12);
            font-weight: 500;
        }
    }

    &.refill-button {
        a {
            background-color: $cta-blue;
            &::before {
                content: "";
                background-image: url("../../assets/img/icon-refill.svg");
                background-repeat: no-repeat;
                position: relative;
                display: inline-block;
                margin-right: rem(5);
                width: rem(30);
                height: rem(30);
                vertical-align: middle;
            }
        }
    }

    &.login-button {
        a {
            &::before {
                content: "";
                background-image: url("../../assets/img/icon-account-login.svg");
                position: relative;
                display: inline-block;
                margin-right: rem(5);
                width: rem(30);
                height: rem(30);
                vertical-align: middle;
            }
        }
    }

    &.secondary-action-button {
        padding: rem(15);
        font-size: rem(12);
        text-transform: uppercase;
        border-bottom: none;
        text-align: center;
        font-weight: 500;

        .off-canvas-menu__link {
            color: $cta-sky;
            padding: rem(15) rem(2);
            display: inline;
            font-size: rem(12);
            font-weight: 500;
        }
    }
}

.off-canvas-menu__item--account {
    border-bottom: 1px solid $tundora;

    .site-header__account-link {
        display: block;
        margin: 0;
        padding: rem(14) rem(20) rem(14) rem(44);
        font-size: rem(16);

        &:before {
            left: 23px;
            top: 18px;
        }
    }
}

.off-canvas-menu__link {
    display: block;
    position: relative;
    color: $white;
    padding: rem(18) rem(15);
    font-size: rem(18);
    font-weight: 300;
    transition: all 0.2s ease-in;
    text-decoration: none;
    border-left: 3px solid transparent;
    border-bottom: 1px solid $tundora;
    a {
        display: block;
        position: relative;
        color: $white;
        font-size: rem(18);
        font-weight: 300;
        text-decoration: none;
    }

    &.active {
        border-left-color: $cta-sky;
    }

    @at-root .off-canvas-menu__item.active > & {
        border-left-color: $cta-sky;
    }
}

.not-mobile .off-canvas-menu__link:hover {
    background: $cta-blue;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;;
        border-left: 5px solid $cta-sky;

    }
}

.offcanvas .fa {
    color: #fefefe;
}

.offcanvas .is-accordion-submenu-parent > a::after {
    border-color: rgba(254, 254, 254, 0.6) transparent transparent;
}

.offcanvas .social-links {
    margin-left: 20px;
}

.offcanvas .social-links li {
    margin-right: 5px;
}

.offcanvas .social-links a:hover .fa {
    color: #bebebe;
    transition: color .2s ease-in;
}

.offcanvas .social-links .fa {
    font-size: 2.2rem;
}

/* .is-open + .off-canvas-content .hamburger .line:nth-child(1),
.is-open + .off-canvas-content .hamburger .line:nth-child(3) {
  width: 35px;
} */

.offcanvas-trigger[aria-expanded="true"] .hamburger .line:nth-child(1) {
    @include transform(translateX(0px) translateY(6px) rotate(-45deg));
}

.offcanvas-trigger[aria-expanded="true"] .hamburger .line:nth-child(3) {
    @include transform(translateX(0px) translateY(-6px) rotate(45deg));
}

.offcanvas-trigger[aria-expanded="true"] .hamburger .line:nth-child(2) { display: none; }

.hamburger {
    width: 22px;
    height: 15px;
}

.hamburger .line {
    display: block;
    position: absolute;
    right: rem(5);
    width: 22px;
    height: 2px;
    background-color: #fefefe;
    transition: all 0.3s ease-in-out;
    border-radius: 2px;
    -webkit-transform-origin: 11px 1px;
    -moz-transform-origin: 11px 1px;
    -ms-transform-origin: 11px 1px;
    -o-transform-origin: 11px 1px;
    transform-origin: 11px 1px;
}

.hamburger .line:nth-child(1) {
    top: 0;
}

.hamburger .line:nth-child(2) {
    top: 6px;
}

.hamburger .line:nth-child(3) {
    top: 12px;
}

.language-list {
    margin: 0;
    padding: 0;
    list-style: none;
    padding: 0 rem(20);
    font-size: rem(14);
    font-weight: 200;

    &__item {
        display: inline-block;

        /*&:nth-child(2) {
            margin-left: rem(10);
            padding-left: rem(15);
            border-left: 1px solid $white;
        }*/
    }

    &__link {
        display: inline-block;
        min-width: rem(100);
        font-size: rem(12);
        font-weight: 500;
        text-decoration: none;
        color: $white;
        margin: 20px;
        border: 1px solid $tundora;
        padding: rem(5) rem(10);
        background: $black;
    }
}

@include breakpoint($large) {
    .offcanvas-trigger {
        display: none;
    }
}

// Main Nav
// ------------------------------------------------------------------------------
//	<div class="main-nav">
//		<ul class="main-nav__list">
//			<li class="main-nav__item">
//				<a class="main-nav__link main-nav__link--active"></a>
//				<a class="main-nav__link"></a>
//			</li>
//		</ul>
//	</div>11
//

/* Mobile main nav styles */

.main-nav {
    $block: &;
    width: 100%;
    font-size: rem(16);
    line-height: 1;
    text-align: center;
    position: relative;

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;

        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        // box-shadow: 0 0 4px 0 rgba(0,0,0,0.13);
        @include breakpoint($large) {
            // border-left: 1px solid $main-nav-border;
            // border-right: 1px solid $main-nav-border;
            font-size: rem(18);
            // margin-left: rem(-25); // align with logo
        }
        li.main-nav__item:not(:first-of-type){
            padding-left:rem(25);
            .litepay-page & {
                padding-left: 0;
            }
        }
    }

    button.button, a.button {
        position: absolute;
        right: 0;
        top: rem(15);
        &--inline {
            margin-right: 0;
        }

        .dashboard & {
          padding-left: rem(25);
          padding-right: rem(25);
        }
    }

    &__item {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        box-flex: 1;
        -webkit-flex: 1 1 100%;
        -moz-flex: 1 1 100%;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        display: inline-block;
        display: inline-block;
        // border-right: 1px solid $main-nav-border;
        &:last-child {
            border-right: none;
        }

        .accordion {
            &__icon {
                position: absolute;
                content: "";
                top: 50%;
                @include transform(translateY(-50%));
                right: 70%;
                @include breakpoint($large) {
                    right: 10px;
                }

                .line {
                    $block: &;
                    width: 8px;
                    height: 1px;
                    background-color: $cta-sky;
                    display: block;
                    margin: 5px auto;
                    transition: all 0.3s ease-in-out;
                    border-radius: 2px;

                    &:nth-child(1) {
                        @include transform( translateX(4px) translateY(4px) rotate(45deg));
                    }

                    &:nth-child(2) {
                        @include transform( translateX(9px) translateY(-2px) rotate(-45deg));
                    }
                }
            }
        }

        &.is_open {
            #{$block}__link {
                border-color: $cta-blue;
            }

            .accordion__icon {

                .line:nth-child(1) {
                    @include transform( translateX(3px) translateY(4px) rotate(-45deg));
                }

                .line:nth-child(2) {
                    @include transform( translateX(8px) translateY(-2px) rotate(45deg));
                }
            }
        }

        @include breakpoint($large) {
            position: relative;
            flex: none;
        }
    }

    &__link {
        display: block;
        padding: rem(25) rem(20);
        min-height: 100%;
        text-decoration: none;
        color: $prepaid-text;
        border-bottom: 3px solid transparent;
        font-size: rem(18);
        transition: border-color .25s ease-out;
        margin-right: rem(5);

        html[lang="es"] & {
            padding: rem(25) rem(12);
        }

        .active &,
        &:hover,
        &:active,
        &:focus {
            // background: $main-nav-link-hover;
            border-color: $cta-sky;
            outline: none;
        }
        &:hover {
            cursor: pointer;
        }

        @include breakpoint($xlarge) {
            &,
            html[lang="es"] & {
                padding: rem(25) rem(30);
            }
        }
    }
}

.main-flyout {
    @include clearfix;
    padding: 0;
    margin: 0;
    list-style: none;
    display: none;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    font-size: rem(15);
    line-height: 1;
    text-align: left;
    z-index: -1;
    // border-top: 1px solid $main-nav-border;
    @include breakpoint($large) {
        width: auto;
        min-width: 250px;
    }

    .is_open & {
        display: block;
        opacity: 1;
    }

    &__item {
        border-bottom: 1px solid $fill-grey;
        background: rgba($white, 0.95);
        transition: background 0.12s ease-in-out;

        &:first-child {
            border-top: 1px solid $fill-grey;
        }

        &:hover {
            background-color: rgba(229,229,229,0.95);
        }
    }

    &__link {
        display: block;
        position: relative;
        padding: rem(15) rem(10);
        text-decoration: none;
        font-size: rem(12);
        line-height: rem(18);
        font-weight: 500;
        color: $tundora;
        transition: background .25s ease-out;

        @at-root .not-mobile &:hover {
            // color: $blue-link-hover;
            // &:before {
            //     content: "";
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     height: 100%;
            //     border-left: 5px solid $blue-cta;
            // }
        }
    }
}

