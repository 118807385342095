%btn {
  cursor: pointer;
  -webkit-appearance: none;
  border: 0;
  display: inline-block;
  text-decoration: none;
}

.small-text-button { //currently only used in the what's this cvv modal button
    padding: 0;
    min-width: 0;
    font-size: rem(12);

    &, &:hover {
        background: transparent;
        color: $cta-blue;
        border: 0;
    }
}
.basic-button {
    @extend %btn;
    background: transparent;
}
.link-callout { //usually a button
    @extend %btn;
    @include reset-button();
    text-transform: uppercase;
    text-decoration: none;
    font-size: rem(14);
    color: $cta-blue;
    fill: $cta-blue;
    border: none;
    font-weight: 500;
    padding: rem(10) 0;
    min-width: 0; //for when it's being applied to a button
    padding-top: 0;
    padding-bottom: 0;

    &:hover {
        background: transparent;
        color: darken($cta-sky, 0.5);
        fill: darken($cta-sky, 0.5);
    }

    &__icon {
        display: inline-block;
        width: 0.7em;
        height: 0.7em;
        vertical-align: middle;
    }

    @include breakpoint($medium) { font-weight: 500; }

    //when the link callout is in close proximity to a button, this helps the text align
    &--button-padding {
        padding: rem(13) rem(15);
    }

    &--nocaps {
        text-transform: none;
        font-size: rem(16);
    }
}
.link-external {
    &::after {
        content: "";
        background-image: url("../../assets/img/external-link.svg");
        background-size: cover;
        position: relative;
        display: inline-block;
        top: -1px;
        margin-left: rem(10);
        width: rem(16);
        height: rem(16);
        vertical-align: middle;
    }
}
.link-button {

  @extend %btn;
  color: white;
  fill: white;
  padding: .8125rem 1.0625rem;
  margin: rem(20) auto;
  background: $cta-blue;
  text-align: center;
  font-size: rem(14);
  line-height: 1.2;
  font-weight: 400;
  width: auto;
  min-width: 7.8125rem;
  max-width: 100%;
  border-radius: rem(25);
  transition: background-color 200ms, color 200ms, opacity 200ms;

  &:hover {
    background: darken(desaturate($cta-blue, 1.50), 4.0);
    color: white;
    fill: white;
    text-decoration: none
  }
}

