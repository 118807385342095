%card {
    background: white;
    position: relative;
    // margin: rem($v-gap) rem(-15);
    margin: 0 rem(-15) rem(20);
    padding: 0 rem(15) spacing('sm');
    // font-size: rem(16);
    &.reset-margin {
      margin: 0 0 rem(20);
        @include breakpoint($medium) {
          margin: 0 0 spacing('xs', 0.5);
      }
    }


    > :last-child { margin-bottom: 0; } //an attempt to get the bottom margin to be "universal"

    @include breakpoint($medium) {
        padding: spacing('sm') spacing('sm', 0.5);
        // margin: rem($v-gap) 0;
        margin: 0 0 spacing('xs', 0.5);
    }

    @include breakpoint($large) {
        padding-left: spacing('sm');
        padding-right: spacing('sm');
    }
}
.card {
  &.validation-error {
    border-color: $error;
    outline-color: $error;
    box-shadow: 0 0 8px 0 rgba(212, 0, 0, 0.42);
    .checkbox__label:before {
      top: rem(25);
    }
    .validation-error {
      margin-left: rem(35);
    }
  }
  &-promo {
    padding-top: 0;
    @include breakpoint($large) {
      padding-top: spacing('sm');
    }
  }
  &__disclaimer{
    font-size:14px;
    font-weight:100;
  }
    @extend %card;

    &--small { padding-top: spacing('xxs'); padding-bottom: spacing('xxs'); }

    &--clear {
        background: none;
        margin: 0 0 rem(20);
        @include breakpoint($large) {
            margin: 0 0 spacing('xs');
        }
    }
    &--right {
        text-align: right;
        padding-right: 0;
        .button {
            margin: 0 0 0 rem(10)!important;
        }
    }
    &--clear-zero {
        @extend .card--clear;
        padding-left: 0; padding-right: 0;
    }

    &--order-total {
        border-bottom: 2px solid $fill-grey;
        border-top: 2px solid $fill-grey;
        padding-top: rem(15);
        padding-bottom: rem(15);
        // max-width: 450px;
        margin-left: auto;
        background-color: white;
        .buyflow-prepaid & {
          background-color: transparent;
        }
        .sticky-cart & {
          background-color: transparent;
        }


    }

    &--order-subtotal {
        padding-top: rem(15);
        padding-bottom: rem(15);
        margin: 0;
        background-color: white;
        .buyflow-prepaid & {
          background-color: transparent;
          border-top: none;
        }
        border-top: 2px solid $fill-grey;
        .sticky-cart & {
          background-color: transparent;
        }
    }

    &__header {
        position: relative;
        top:-5px;
        margin-bottom: rem(10);
        border-bottom: 2px solid $fill-grey;

        &-title {
            margin: 0 0 rem(10);
            font-size: rem(18);
          line-height: 1.5;
            font-weight: 300;
            max-width: 60%;
            padding-top: 2rem;

            @include breakpoint($medium) { font-size: rem(25); }
            @include breakpoint($large) { font-size: rem(24); }
        }
        &-subtitle {
            font-size: rem(14);
            font-weight: 500;
        }
    }

    &__summary {
        label {
            color: $prepaid-text;
            font-size: rem(12);
            line-height: 18px;
            font-weight: 500;
        }
        input {
            color: $prepaid-text;
            font-size: rem(14);
            line-height: 22px;
            font-weight: 300;
            @include breakpoint($large) {
                font-size: rem(16);
            }
        }
        textarea {
            color: $prepaid-text;
            font-size: rem(14);
            line-height: 1.5;
            height: auto;
            font-weight: 300;
            @include breakpoint($large) {
                font-size: rem(16);
            }
        }

    }
    
    &__centered {
      text-align: center;
    }

    &--small-title {
        .card__header-title {
            font-size: rem(18);
            @include breakpoint($medium) { font-size: rem(18); }
            @include breakpoint($large) { font-size: rem(18); }
        }
    }
  &.dashboard {
    position: relative;
    .edit-link {
      position: absolute;
      right: 0;
      top: 0;
      color: $cta-blue;
      background-color: transparent;
      font-size: rem(14);
      font-weight: 400;
      border: none;
      cursor: pointer;
    }
    fieldset {
      button {
        float: right;
      }
    }
    .communications {
      padding-bottom: rem(20);
      a {
        text-decoration: none;
        font-size: rem(14);
        font-weight: 400;
      }
    }
    .card__summary {
      .icon {
        width: 20px;
        height: 12px;
        margin-left: rem(5);

      }
      label {
        margin: rem(10) 0 rem(5) 0;
      }
      .vertical-margin-block {
        margin: rem(2) 0;
      }
    }
  }
}
