.order-confirm-accordion {
  $block: &;

  background: #fff;
  margin: spacing('sm') rem(-15);

  @include breakpoint($medium) {
    margin: spacing('sm') 0;
  }

  &__title {
    // color: $cod-grey;
    cursor: default;
    font-size: rem(18);
    line-height: rem(20);
    font-weight: 400;
    margin: 0;
    @include breakpoint($large) {
      font-size: rem(24);
      line-height: rem(26);
    }
    b {
      margin-left: rem(5);
    }
  }

  &__total {
    font-weight: 500;

    span, sup {
      font-size: rem(12);
      position: relative;
      top: -4px;
      transform: translate(0);
    }
  }

  &__tab{
    padding: rem(30);
    @include breakpoint($medium) {
      padding: rem(30);
    }
    @include breakpoint($large) {
      padding-left: spacing('sm');
      padding-right: spacing('sm');
    }
  }

  &__tab {
    position: relative;
    margin: 0;
    padding-right: spacing('xs', 2);
    font-size: rem(14);
    font-weight: 200;
    line-height: rem(22);
    cursor: pointer;
    border-left: 6px solid $porcelain;
    border-color: $porcelain;
    transition: border-color 0.2s ease-in-out;
    @include breakpoint ($large) {
      font-size: rem(18);
      font-weight: 300;
      line-height: rem(27);
    }

    &:focus {
      outline: 0;
      border-color: $cta-blue;
    }
    @include breakpoint($large) {
      padding-right: rem(60);
    }
    button {
      margin-left: 0;
    }
  }

  &__panel {
    display: none;
    width: 100%;
    margin: 0;
    padding: 0 rem(15);

    @include breakpoint($medium) {
      padding: 0;
    }
  }
  &__group {
    cursor: default;
    font-size: rem(18);
    line-height: rem(20);
    font-weight: 300;
    margin-bottom: rem(10);
    @include breakpoint($large) {
      font-size: rem(24);
      line-height: rem(26);
    }
  }
  .cart {
    padding: 0 rem(20);
  }

  .card {
    .accordion__tab {
      font-size: rem(14);
      font-weight: 300;
      @include breakpoint($large) {
        font-size: rem(16);
      }
    }
    &.card--order-subtotal, &.card--order-total {
      padding-right: 0;
      @include breakpoint($medium) {
        padding-right: rem(15);
      }
      @include breakpoint($large) {
        padding-right: rem(30);
      }
    }
    &.card--order-total {
      border-bottom: none;
    }
  }
}

.confirmation-card {
  padding: rem(20);
  margin: rem(30) 0 0;
  .modal-button-container {
    max-width: 100%;
  }
}
.confirmation-notification {
  padding: rem(10) rem(20);
  background-color: $porcelain;
  font-weight: 500;
  text-transform: uppercase;
}


.download-app__icon {
  text-decoration: none;
  margin-right: rem(20);
}
