.price {
    display: inline-block;
    font-weight: 600;

    &__table {
        display: table;
    }

        &__symbol {
            display: table-cell;
            vertical-align: top;
            font-size: rem(10);
            line-height: 1;
            position: relative;
            top: 2px;
        }
        &__amount {
            display: table-cell;
            vertical-align: top;
            font-size: rem(18);
            line-height: 1;
            white-space: nowrap;
        }
        &__decimal {
            display: table-cell;
            vertical-align: top;
            font-size: rem(16);
            line-height: 1;
            position: relative;
            top: 2px;
        }

    &--large {
        .price__symbol { font-size: rem(16); }
        .price__amount { font-size: rem(28); }
    }
}

.price-full {
    display: inline-block;
    font-weight: 600;
    font-size: rem(18);
    span, sup {
        font-size: rem(12);
        position: relative;
        top: -4px;
        transform: translate(0);
    }
    &.price-acp-total {
        margin-top: 0;
        padding-top: rem(10);
        border-top: 1px solid rgba(147, 147, 147, 0.4);
        min-width: rem(70);
    }
    &.price-old {
            color: #939393;
            position: relative;

            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background: $xfinity-red;
                transform-origin: right;
                transform: rotate(-30deg);
                opacity: 1;
                top: 4px;
                position: relative;
            }
    }
}

.price-wrap {
    vertical-align: middle;
    display: inline-block;
    margin-right: spacing('xxs');

    &--multi {
        background: $black-haze;
        border-left: 6px solid $success;
        padding: rem(10) rem(50) rem(10) rem(10);
        margin-left: spacing('xxs', -1);
        border-radius: rem(3);
        display: block;
        @include breakpoint($medium) {
            display: inline-block;
            margin-left: rem(10);
        }
    }

    &__amount,
    &__label {
        line-height: 1.25;
        color: $prepaid-text;
        @at-root .price-wrap--multi & {
            &, a, button {
                color: $prepaid-text;
                margin: rem(0);
            }
        }
    }
    &__amount {
        font-size: rem(32);
        font-weight: bold;

        span, sup {
            font-size: rem(18);
            position: relative;
            top: -8px;
            transform: translate(0);
        }

        @at-root .price-wrap--old & {
            // opacity: 0.4;
            color: #939393;
            position: relative;

            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background: $xfinity-red;
                transform-origin: right;
                transform: rotate(-30deg);
                opacity: 1;
                top: 4px;
                position: relative;
            }
        }
    }
    &__label {
        font-size: rem(10);
        strong {
            display: block;
            font-size: rem(12);
        }
        span {
            min-height: 12px;
        }
        .modal-item {
            button {
                padding-top: rem(2);
            }
        }
      .direct-promo & {
        max-width: rem(130);
      }
    }

}
