.auto-refill {
    &-badge {
        margin-bottom: rem(10);
        background: #F2F4F4;
        border-left: 6px solid #47C684;
        border-top: none;
        border-right: none;
        border-bottom: none;
        padding: 8px 10px 8px 10px;
        border-radius: 3px;
        color: $tundora;
        font-size: rem(12);
        font-weight: 500;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-decoration: none;
        cursor: pointer;
        button {
            text-decoration: none;
            color: $tundora;
        }
    }
    a {
        text-decoration: none;
    }
    &__disclaimer{
      margin-bottom: rem(10);
        @include breakpoint($medium) {
            font-size:16px;
            font-weight:100;
        }
    }
}