.move-refill {
    & .move {
        .page__title {
            margin-bottom: 0;
            padding: 0;
        }
        .fullbleed-offsets {
            margin: 0;
            @include breakpoint($large) {
                padding: rem(20) 0;
            }
        }
        .accordion {
            margin-top: 2px!important;
            .accordion__tab--refill-selector {
                display: none;
            }
        }
    }
    &-confirmation {
        .accordion {
            margin-bottom: 0;
        }
    }
}