/* -------------------------------------------------------------------------- *\
 * Necessary styling for the dialog to work
 * -------------------------------------------------------------------------- */

.modal-open {
    // my addition to body element
    overflow: hidden;
    //Fix for 
    .dashboard{
        position:fixed;
    }
}


/* -------------------------------------------------------------------------- *\
 * Styling to make the dialog look like a dialog
 * -------------------------------------------------------------------------- */

.modal {
    $block: &;

    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    overflow: auto;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.33s ease-in-out;

    &--visible {
        opacity: 1;
        pointer-events: auto;
    }
    &-item {
        .button--link {
            margin: 0;
            &.margin-left {
                margin-left: rem(5);
            }
        }
    }
    &.welcome-modal {
        .modal__content {
            width: 100%;
            max-width: 100%;
            height: 100vh;
            max-height: 100vh;
            border-radius: 5px;
    
            @include breakpoint($large) {
                width: 860px;
                height: auto;
                max-height: calc(100vh - 100px);
            }
            .first-step {
                &.modal__body {
                    text-align: center;
                    img.success {
                        margin: rem(60) auto rem(40);
                        width: 30%;
                        max-width: rem(150);
                    }
                    .success-title {
                        text-transform: uppercase;
                        font-size: rem(16);
                        margin-bottom: rem(20);
                    }
                    h1 {
                        font-weight: 600;
                    }
                    .button {
                        width: 80%;
                        max-width: rem(220);
                        display: block;
                        margin-bottom: rem(40);
                        &--link {
                            text-transform: uppercase;
                            font-size: rem(14);
                        }
                    }
                }
            }
        }
        .welcome-modal__header {
            padding: rem(20) rem(20) 0;
        }
        .welcome-modal__title {
            margin-top: rem(20);
            margin-bottom: rem(20);
            font-size: rem(18);
            font-weight: 500;
        }
        .animation {
            position: relative;
            display: block;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
            @include breakpoint($medium) {
                max-width: 400px;
            }
        }
    }

    &__overlay {
        background-color: rgba($black, 0.66);
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 23;
    }

    &__content {
        background: $white;
        overflow-y: auto;
        position: relative;
        opacity: 0;
        margin-top: rem(30);
        transition: opacity 0.33s ease-in-out, margin-top 0.1s ease-in-out 0.5s;
        max-height: calc(100vh - 100px);
        z-index: 100001;
        color: $prepaid-text;

        .modal--visible & {
            opacity: 1;
            margin-top: 0;
            transition: opacity 0.33s ease-in-out, margin-top 0.33s ease-in-out;
        }
        .move-modal{
            padding:0 1rem;
            &--title{
                font-weight: 700;
            }
            &--subtitle{
                position: relative;
                padding:0 2rem;
                font-weight:700;
                line-height:2rem;
                margin-bottom:1rem;

                &:before{
                    height:100%;
                    width:2.5rem;
                    background:#e1e1e1;
                    content:"";
                    position:absolute;
                    background-image: url('../../assets/img/avatar.svg');
                    background-repeat:no-repeat;
                    background-position-y: 50%;
                    background-position-x: calc(100% - 5px);
                    left:-1rem;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }
            &--addresses{
                position: relative;
                &:before{
                    content:"";
                    border-left:2px solid #e1e1e1;
                    position: absolute;
                    height: calc(100% + 2rem);
                    width: 2px;
                    top: -2rem;
                    left:-1rem;
                }
            }
            .btn-date{
                position: absolute;
                right:0;
                text-transform: uppercase;
            }
        }
    
    }

    &__title {
        font-size: rem(24);
        margin-bottom: rem(20);
    }

    &__sub__title {
        @include breakpoint($large) {
            margin-bottom: rem(18);
        }
    }


    /* -------------------------------------------------------------------------- *\
    * Extra dialog styling to make it shiny
    * -------------------------------------------------------------------------- */

    &__content {
        width: 90%;
        max-width: 100%;
        height: 80vh;
        max-height: 100vh;
        border-radius: 5px;

        @include breakpoint($large) {
            width: 860px;
            height: auto;
            max-height: calc(100vh - 100px);
        }

        &.full-screen {
            width: 100%;
            height: 100%;
            border-radius: 0;
            @include breakpoint($large) {
            width: 860px;
            height: auto;
            border-radius: 5px;
        }

            @include breakpoint(max-width $max-medium) {
                .modal__close {
                    position: fixed;
                    right: 20px;
                }
            }
        }
      &.video-modal {
        background-color: black;
        .modal__close {
          color: white;
          .icon {
            fill: white;
          }
        }
      }
    }

    &__body {
        $side-padding: rem(15);
        $side-padding-md: rem(76);
        padding: rem(50) $side-padding rem(10);
        text-align: left;
        @include breakpoint($medium) {
            padding: rem(62) $side-padding-md;
        }
        li {
          font-size: rem(16);
          font-weight: 200;
        }
        p {
            margin-bottom: rem(25);
        }
        .container-50-50 {
            width: 100%;
            display: block;
            padding: rem(20) 0;
            @include breakpoint($medium) {
                width: 60%;
                display: inline-block;
                &:nth-of-type(even) {
                    padding: 20px 20px 0 0
                }
                &:nth-of-type(odd) {
                     padding: 20px 0 0 20px
                 }
            }
            img {
                padding: rem(5);
                @include breakpoint($medium) {
                    padding: rem(3) rem(6) rem(3) 0;
                }
            }
        }
        .video-wrapper {
            position: relative;
            padding-bottom: 56.25%; /* 16:9 */
            padding-top: 25px;
            height: 0;

            .video {
                position: absolute;
                margin-top: rem(30);
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                @include breakpoint($large) {
                    margin-top: 0;
                }
            }
        }
        .full-width {
            margin-left: -$side-padding;
            margin-right: -$side-padding;
            @include breakpoint($medium) {
                margin-left: -$side-padding-md;
                margin-right: -$side-padding-md;
            }
        }
    }

    &__no-padding {
        #{$block}__body {
            padding: 0;
        }
    }

    &__no-side-padding {
        #{$block}__body {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__overlay {
        background-color: rgba(43, 46, 56, 0.9);
    }

    &__header {
        display: table;
        position: relative;
        padding: rem(10);
        width: 100%;

        h2 {
            display: table-cell;
            font-weight: 400;
            vertical-align: middle;
            width: 100%;
        }
    }

    &__back,
    &__close {
        display: table-cell;
        cursor: pointer;
        text-align: center;
        background: none;
        border: none;
        transition: 0.15s;
        font-weight: 500;
        line-height: 1;
        position: absolute;
        top: rem(8);
    }

    &__back {
        left: rem(10);
        padding-left: 0;

        @include breakpoint($medium) {
            left: rem(20);
        }
        &:before {
            content: '';
            // @include inline-svg($icon-arrow, $dialog-close);
            display: inline-block;
            width: rem(18);
            height: rem(18);
            vertical-align: middle;
            margin-right: rem(10);
        }
    }

    &__close {
        @extend .l1;
        // text-transform: uppercase;
        padding: rem(5);
        right: rem(10);
        margin-top: rem(5);
        display: flex;
        align-items: center;

        @include breakpoint($medium) {
            right: rem(15);
        }

        .icon {
            position: relative;
            margin-left: spacing('xxs', 2);
        }

    }

    &--premium {
        #{$block}__flex-wrap {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            align-content: stretch;
            min-height: 100vh;

            @include breakpoint($medium) {
                flex-direction: row;
                height: auto;
                min-height: 400px;
            }

        }
        #{$block}__section {
            padding: rem(50) rem(15) rem(10);
            @include breakpoint($max-medium) {
                min-height: 400px;
            }

            &:first-child {
              z-index: 2;
                background: $black-haze;
                @include breakpoint($max-medium) {
                    padding: rem(45) rem(30);
                    min-width: rem(340);
                    max-width: rem(350);
                }
                @include breakpoint(max-width $max-medium) {
                    position: fixed;
                    top: 0;
                    width: 100%;
                }

            }
            &:last-child {
              z-index: 1;
                background: $cod-gray;
                color: $white;
                height: 100%;
                padding: rem(20) rem(15);
                @include breakpoint($medium) {
                    height: auto;
                    width: 100%;
                    padding: rem(70) rem(30) rem(70);
                }

                @include breakpoint(max-width $max-medium) {
                    min-height: 100vh;
                    height: 100%;
                    padding-top: rem(250);
                }
            }
          .logo-container {
            position: relative;
            height: 30px;
            margin: rem(10) rem(10) rem(10) 0;
            width: auto;
            display: inline-block;
            img {
              height: 100%;
              width: auto;
            }

          }
            img {
                display: inline-block;
                @include breakpoint($large) {
                    display: block;
                }
                &.channel {
                    display: inline;
                    margin-bottom: rem(40);
                    margin-right: rem(10);
                    width: 27%;
                    @include breakpoint($medium) {
                        width: 15%;
                    }
                }
                &.program {
                    display: inline;
                    margin-right: rem(20);
                    margin-top: rem(20);
                }
            }
            a {
              font-weight: 500;
                display: inline-block;
                margin-bottom: 10px;
                text-transform: uppercase;
                text-decoration: none;
                cursor: pointer;
                @include breakpoint($large) {
                    display: block;
                }
            }
          .accordion {
            a {
              font-weight: 200;
              font-size: rem(16);
              text-transform: none;
              text-decoration: underline;
              display: inline;
              margin-bottom: 0;
            }
          }
            .feat-programs__title {
                max-width: 100%;
                margin-left: 0;
            }
            .checkbox-tile__price {
                display: block;
              &-duration {
                font-weight: 200;
                vertical-align: bottom;
              }
            }
          .package-channels__list {
            margin: rem(20) 0;
          }
        }
        #{$block}__close {
          z-index: 2;
          @extend .l1;
          // text-transform: uppercase;
          padding: rem(5);
          right: rem(10);
          top: rem(50);

          @include breakpoint($medium) {
            right: rem(15);
            top: rem(8);
          }

          .icon {
            position: relative;
            top: 0px;
            margin-left: spacing('xxs');
            display: inline-block;
            &.small-medium {
                display: inline-block;
                @include breakpoint ($large) {
                    display: none;
                }
            }
            &.large {
                display: none;
                @include breakpoint ($large) {
                    display: inline-block;
                }
            }
          }
            @include breakpoint($large) {
                color: $white;
              fill: $white;
            }
        }
        .button--container {
            margin-top: rem(30);
            margin-bottom: rem(50);
            @include breakpoint($large) {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
}
