.account-picker {
    .main {
        padding-top: rem(40);
        min-height: calc(100vh - 254px);
        padding-bottom: rem(80);
        @include breakpoint ($medium) {
            min-height: calc(100vh - 184px);
        }
    }
    .numberCircle {
        display: none;
    }
    .header__bottom {
        display: none;
    }
    .off-canvas-menu__link {
        display: none;
    }
    .off-canvas-menu__item {
        .off-canvas-menu__link {
            display: block;
        }
    }
    .main__width-constraint {
        max-width: rem(570);
    }
    &-subtitle {
        padding: rem(10) 0;
        font-size: rem(16);
        font-weight: 200;
        line-height: rem(24);
    }
    .button.continue {
        margin: 0 auto;
        display: block;
        min-width: rem(200);
    }
    .sticky-cta {
        display: none;
    }
    .footer {
        padding: rem(40) $site-padding;
        @include breakpoint($large) {
            padding: rem(40) rem(130);
          }
    }
    .refill-button {
        display: none;
    }
}