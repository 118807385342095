.checkbox-tile {
  background: $black-haze;
  margin-bottom: spacing('xs', 0.5);
  border-bottom-left-radius: rem(3);
  border-bottom-right-radius: rem(3);
  min-height: rem(207);
  &-spanish {
    min-height: auto;
  }
  // overflow: hidden;

  .logo-container {
    position: relative;
    width: 100%;
    height: 30px;
    margin: rem(10) 0;
    img {
      height: 100%;
      width: auto;
    }
  }

  .checkbox {
    display: block;

    &__label {
      text-align: center;
      padding-left: 0;
      padding-top: rem(35);
      font-size: rem(12);
      font-weight: 500;
      color: $prepaid-text;

      &:before {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__title {
    font-size: rem(12);
    font-weight: 500;
    margin: rem(5) 0;
    text-transform: uppercase;
  }

  &__price {
    font-size: rem(25);
    font-weight: 700;
    span,
    sup {
      font-size: rem(15);
      position: relative;
      top: -6px;
      transform: translate(0);
    }
  }

  &__wrap {
    padding: rem(15) rem(5);
  }
  &__learn-more {
  }
  &__button {
    color: $prepaid-text;

    text-align: center;
    margin: 0 auto;
    display: block;
    width: 100%;
    background: $porcelain;
    padding: spacing('xs', 0.5) spacing('xxs');

    border-bottom-left-radius: rem(3);
    border-bottom-right-radius: rem(3);

    &:hover {
      background: $porcelain;
    }
  }
}

.saveCheckbox {
  padding-left: 8px;
}
@media only screen and (max-width: 768px) {
  .saveCheckbox {
    padding-left: 23px;
  }
}