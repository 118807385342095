.service-provider-grid{
  &--title{
    padding:0.9375rem 2.5rem 0.9375rem 1.25rem;
  }
  &--cells{
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      grid-gap: 1rem;
      padding:0.9375rem 2.5rem 0.9375rem 1.25rem;

      .cell{
        text-align: center;
        position: relative;
          background:#F2F4F4;
          padding: 1rem;
          display: grid;
          place-items: center;
          img {
            width: 100%;
            margin:10px 0;
          }
          .price{
            font-size: 24px;
            line-height:26px;
          }
          .price-row{
            display:flex;
            align-items: flex-start;
            justify-content: center;
          }
          padding-bottom:50px;
          

      }
      .check-box{
        height:24px;
        width:24px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("../../assets/img/check-box-empty.svg");
        border:none;
        cursor:pointer;

        &--active{
            background-image: url("../../assets/img/check-box-active.svg");
        }
    }
    .footer-btn{
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border: none;
      background: #E3E7E9;
      padding: 8px 0;
      cursor:pointer;
    }
  }
}