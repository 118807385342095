.broadband-facts {
  margin-top: 1rem;
  .card {
    padding: 0;
    margin-top: 1rem !important;
    border-radius: 8px;
    .card-body {
      padding: 1.6rem 1.3375rem 1.875rem;
      overflow: hidden;
      line-height: 2.2em;
      height: auto;
    }
    .expand {
      height: auto;
    }
    .collapse {
      height: 259px;
      @include breakpoint($medium) {
        height: 246px;
      }
    }
    .card-footer {
      button {
        top: 0;
        display: block;
        position: relative;
        width: 100%;
        background-color: #e3e7e9;
      }
      .cima-label {
        text-transform: none;
        height: 32px;
      }
      .read-less-button-text {
        &:after {
          content: "";
          display: inline-block;
          position: absolute;
          width: 30px;
          height: 25px;
          background-image: url(../../assets/img/down-arrow.svg);
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 1px;
          transform: rotate(180deg);
        }
      }
      .read-more-button-text {
        &:after {
          content: "";
          display: inline-block;
          position: absolute;
          width: 30px;
          height: 25px;
          background-image: url(../../assets/img/down-arrow.svg);

          background-repeat: no-repeat;
          background-size: contain;
          bottom: 1px;
        }

        @include breakpoint($large) {
          margin: 0 0 rem(20);
        }
      }
    }
  }
}
