.progress-spinner {
  &__loader {
    display: flex;
    position: relative;
    width: 100%;
    height: calc(100vh - #{rem(350)});
    align-content: center;
    align-items: center;
    text-align: center;
  }

  &__text {
    flex: 1;
    span {
      display: inline-block;
      max-width: 100px;
      font-weight: 400;
      font-size: rem(12);
      line-height: rem(22);

      @at-root .progress-spinner--dark & {
        color: #fff;
      }
    }
  }

  &__timeout {
    position: absolute;
    bottom: rem(20);
    width: 100%;
    text-align: center;
  }

  &__circle {
    &:before {
      content: '';
      position: absolute;
      width: 155px;
      height: 155px;
      border: 2px solid $alto;
      border-radius: 100%;
      top: 50%;
      left: 50%;
      @include transform( translate(-50%, -50%));

      @at-root .progress-spinner--dark & {
        border-color: $black;
      }
    }
    &:after {
      content: '';
      position: absolute;
      width: 155px;
      height: 155px;
      border: 2px solid $cta-sky;
      border-top-color: transparent;
      border-left-color: transparent;
      border-radius: 100%;
      animation: lds-rolling 1s linear infinite;
      top: 50%;
      left: 50%;
    }
  }
}

@keyframes lds-rolling {
  0% {
    @include transform( translate(-50%, -50%) rotate(0deg));
  }
  100% {
    @include transform( translate(-50%, -50%) rotate(360deg));
  }
}
