.direct-promo {
  &.modal__body {
    padding-left: 0;
    padding-right: 0;
    .card-promo {
      padding: 0;
      margin: 0;
      .direct-promo-accordion {
        margin: 0;
        &-v2 {
          padding: rem(20);
          position: relative;
          h4 {
            font-size: rem(18);
            font-weight: 200;
            margin-bottom: rem(10);
          }
          .accordion-link {
            position: absolute;
            right: rem(20);
            top: rem(25);
            text-decoration: none;
            font-weight: 600;
          }
        }
      }
    }
    h3,
    p {
      padding: rem(10) rem(20);
    }
    h3 {
      font-size: rem(24);
      font-weight: bold;
      margin-bottom: 0;
    }
    .button--container {
      margin-top: rem(40);
      padding-bottom: 2.5rem;
    }
    .promo-v2-text {
      padding: 0 rem(20);
      button {
        display: block;
        margin-bottom: rem(15);
      }
      .accordion--text {
        .accordion__tab {
          text-align: left;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
    .accordion--text {
      .accordion__tab {
        max-width: rem(150);
        margin-left: auto;
        margin-right: auto;
      }
      .accordion__icon {
        right: 0;
      }
      .accordion__panel {
        text-align: left;
        padding: rem(5) 0;
        @include breakpoint($medium) {
          padding: rem(5) rem(40);
        }
      }
    }
    .accordion__faq-group {
      p {
        margin-bottom: 0;
        margin-top: 0;
        padding: rem(20) rem(10);
      }
    }
  }
  &__hero-banner {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    right: 50%;
    width: 100vw;
    .modal__body & {
      left: auto;
      margin-left: 0;
      margin-right: 0;
      max-width: none;
      position: relative;
      right: auto;
      width: 100%;
      margin-bottom: 0;
      .direct-promo__hero-banner-container {
        padding: 0 rem(20);
      }
    }
    @include breakpoint($large) {
      margin-bottom: rem(20);
    }
    img {
      width: 100%;
      height: auto;
    }
    .winback & {
      .direct-promo__hero-banner-container {
        color: white;
        bottom: 40%;
        left: 15%;
        width: auto;
        .banner-title {
          font-style: normal;
          font-weight: 500;
          font-size: rem(20);
          @include breakpoint($medium) {
            font-size: rem(26);
          }
        }
      }
      &::after {
        content: "";
        background-image: linear-gradient(
          to left top,
          #c8001d 0%,
          #c8001d 49.5%,
          transparent 50%
        );
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left top;
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        -ms-filter: "FlipH";
        position: absolute;
        width: 50%;
        height: 60%;
        bottom: 0px;
        left: 0;
        z-index: 1;
        @include breakpoint($medium) {
          width: 20%;
          height: 45%;
        }
      }
      &::before {
        content: "";
        background-image: linear-gradient(
          to left top,
          #ffffff 0%,
          #ffffff 49.5%,
          transparent 50%
        );
        position: absolute;
        width: 100%;
        height: 35%;
        bottom: -2px;
        left: 0;
        z-index: 2;
        opacity: 1;
      }
    }
    &::after {
      content: "";
      background-image: url(../../assets/img/gradient-triangle-bottom-right.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: left top;
      position: absolute;
      width: 30%;
      height: 40%;
      bottom: 0px;
      right: 0;
      z-index: 2;
      @include breakpoint($medium) {
        width: 20%;
        height: 50%;
      }
    }
    &::before {
      content: "";
      background-image: linear-gradient(
        to right top,
        rgba(225, 225, 225, 0.9) 0%,
        rgba(225, 225, 225, 0.9) 49.5%,
        transparent 50%
      );
      position: absolute;
      width: 100%;
      height: 35%;
      bottom: 0;
      left: 0;
      z-index: 1;
      opacity: 1;
    }
    &-container {
      position: absolute;
      font-style: italic;
      font-size: rem(16);
      font-weight: 400;
      bottom: 8%;
      right: 0;
      width: 100%;
      margin: 0 auto;
      color: black;
      z-index: 3;
      padding: 0 rem(20);
      @include breakpoint($medium) {
        font-size: rem(22);
        bottom: 12%;
      }
      @include breakpoint($large) {
        padding: 0;
        font-size: rem(22);
        bottom: 12%;
      }
    }
  }
  &-accordion {
    $block: &;

    background: #fff;
    margin: spacing("sm") rem(-15);
    margin-top: 0;

    @include breakpoint($large) {
      margin: spacing("sm") 0;
    }

    &__title {
      // color: $cod-grey;
      cursor: default;
      font-size: rem(18);
      line-height: rem(20);
      font-weight: 400;
      margin: 0;
      @include breakpoint($large) {
        font-size: rem(24);
        line-height: rem(26);
      }
    }
    .accordion__item {
      border-top: 1px solid $alto;

      &:last-child {
        border-bottom: 1px solid $alto;
      }
    }

    &__total {
      font-weight: 500;

      span,
      sup {
        font-size: rem(12);
        position: relative;
        top: -4px;
        transform: translate(0);
      }
    }

    &__tab {
      padding: rem(15);
      @include breakpoint($medium) {
        padding: rem(20);
      }
      @include breakpoint($large) {
        padding-left: spacing("sm");
        padding-right: spacing("sm");
      }
    }

    &__tab {
      position: relative;
      margin: 0;
      padding-right: spacing("xs", 2);
      font-size: rem(14);
      font-weight: 200;
      line-height: rem(22);
      cursor: pointer;
      border-left: 6px solid $cta-blue;
      border-color: $cta-blue;
      transition: border-color 0.2s ease-in-out;
      @include breakpoint($large) {
        font-size: rem(18);
        font-weight: 300;
        line-height: rem(27);
      }

      &:focus {
        outline: 0;
        border-color: $cta-blue;
      }
      @include breakpoint($large) {
        padding-right: rem(60);
      }
      button {
        margin-left: 0;
      }
    }

    &__panel {
      display: none;
      width: 100%;
      margin: 0;
      padding: 0 rem(15);

      @include breakpoint($medium) {
        padding: 0;
      }
    }
    &__group {
      cursor: default;
      font-size: rem(18);
      line-height: rem(20);
      font-weight: 300;
      margin-bottom: rem(10);
      @include breakpoint($large) {
        font-size: rem(24);
        line-height: rem(26);
      }
    }
    .cart {
      padding: 0 rem(20);
    }

    .card {
      .accordion__tab {
        font-size: rem(14);
        font-weight: 300;
        @include breakpoint($large) {
          font-size: rem(16);
        }
      }
      &.card--order-subtotal,
      &.card--order-total {
        display: none;
      }
    }
  }
  .button--link--underline {
    text-align: center;
    width: 100%;
    @include breakpoint($large) {
      text-align: left;
    }
    &.text-center {
      @include breakpoint($large) {
        text-align: center;
      }
    }
  }
}
.promo-card {
  background: none;
  color: inherit;
  border: none;
  padding-top: 0;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  color: $prepaid-text;
  font-size: rem(14);
  @include breakpoint($large) {
    padding-right: 0;
    padding-left: 0;
    font-size: rem(16);
  }
  &_wrapper {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: rem(3);
  }
  &-intro {
    margin-bottom: rem(20);
    font-weight: 500;
  }
  &_img-container {
    position: relative;
    width: 100%;
    img {
      width: 100%;
      height: auto;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    .promo-card_flag {
      position: absolute;
      bottom: 0;
      left: 0;
      height: rem(30);
      padding: rem(5) rem(10);
      background-color: black;
      color: white;
      &:after {
        content: "";
        position: absolute;
        height: 0;
        width: 0;
        right: -30px;
        top: 0;
        border-right: 30px solid transparent;
        border-bottom: 30px solid black;
        margin-bottom: -1px;
      }
    }
  }
  &_text-container {
    background-color: white;
    padding: rem(20);
    text-align: left;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}
.winback-promo-cart-banner {
  padding: rem(10) rem(20);
  background-color: #ebf3f4;
  display: block;
  position: relative;
  .refillIcon {
    display: none;
  }
  &::after {
    content: "";
    background-image: linear-gradient(
      to right bottom,
      #c8001d 0%,
      #c8001d 49.5%,
      transparent 50%
    );
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    -ms-filter: "FlipH";
    position: absolute;
    width: 30%;
    height: 100%;
    top: 0px;
    right: 0;
    z-index: 2;
  }
  &::before {
    content: "";
    background-image: linear-gradient(
      to left top,
      #ffffff 0%,
      #ffffff 49.5%,
      transparent 50%
    );
    position: absolute;
    width: 30%;
    height: 100%;
    bottom: -2px;
    right: 0;
    z-index: 1;
    opacity: 1;
  }
  .title {
    font-size: rem(24);
    font-weight: 500;
    margin-bottom: rem(5);
  }
  .body {
    font-size: rem(16);
    font-weight: 200;
  }
  .emphasis {
    font-size: rem(16);
    font-weight: 200;
    color: #c8001d;
    font-style: italic;
  }
  &.checkout {
    overflow: hidden;
    .refillIcon {
      display: block;
      position: absolute;
      left: 20px;
      top: 15px;
      z-index: 2;
    }
    &::before {
      display: none;
    }
    &::after {
      background-image: linear-gradient(
        to left bottom,
        #c8001d 0%,
        #c8001d 49.5%,
        transparent 50%
      );
      left: 0;
      right: inherit;
      height: 130%;
      width: 40%;
      z-index: 1;
    }
    .title {
      position: relative;
      left: 40%;
    }
    .body {
      position: relative;
      left: 40%;
    }
    .emphasis {
      position: relative;
      left: 40%;
    }
  }
}
.flex-container {
  display: flex;
  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
  .broadband-facts {
    margin-top: 15px;
    @include breakpoint($medium) {
      margin-left: 20px;
    }
  }
}
