html {
  box-sizing: border-box;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  flex-direction: column;
  margin: 0;
  font-size: rem(14);
  line-height: 1.5;
  font-family: XfinityStandard, Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: $prepaid-text;
  text-transform: none;
  letter-spacing: .0125rem;
  background-color: #f2f4f4;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}


.app {
  // display: flex;
  // flex-direction: column;
  height: 100vh;
}

#root {
  min-height: 100vh;
}

.prepaid-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

main {
  flex-grow: 1;
}

.header, main, .footer {
  flex-shrink: 0;
}

.main {
  flex: 1;
  padding: spacing('xs', 0.5) spacing('sm', 0.5) rem(180);
  overflow-x: hidden;

  &__width-constraint {
    max-width: rem($content-width);
    margin: 0 auto;

      &.clear {
          &:after {
              clear: both;
              content: "";
              display: table;
          }
      }

  }

  @include breakpoint($medium) {
    min-height: calc(100vh - 245px);
    padding: spacing('sm') $site-padding 100px $site-padding;
  }

  @include breakpoint($large) {
    padding: spacing('md') $site-padding;
    min-height: calc(100vh - 234px);

    &__desktop-sidebar-right {
      float: right;
      width: calc(40% - 10px);
    }
    &__desktop-body {
      float: left;
      width: calc(60% - 10px);
    }
  }

    &_dashboard {

        padding: 0 0 100px 0;
        @include breakpoint($medium) {
            min-height: calc(100vh - 180px);
        }
      &-with-padding {
        padding: spacing('xs', 0.5) spacing('sm', 0.5) 0;
      }
    }

}

.well {
  $well: &;
  padding: rem(28) rem(40) rem(18) rem(40);
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  @include breakpoint($medium) {
    padding: rem(28) rem(40) rem(18) rem(40);

    &:last-child {
      margin-bottom: 0;
    }
  }
  .icon {
    position: absolute;
    left: rem(10);
    top: 50%;
    transform: translate(0, -50%);
  }

  &,
  & p {
    background: $white !important;
    font-size: rem(14) !important;

    &:last-child {
      margin-bottom: 0;
    }

    @include breakpoint($medium) {
      font-size: rem(16) !important;
    }

    &.text-lg {
      font-size: rem(16) !important;
    }
  }


  &--warning,
  &--warning p {
    background: $warning !important;
  }

  &--clear,
  &--clear p {
    background: none !important;
  }

  &--address {
    padding-top: rem(32);
    padding-bottom: rem(32);
  }

  &__content {
    display: table;
    width: 100%;

    &--icon-xl {
      #{$well}__icon {
        width: rem(40);
        height: rem(40);
      }

      #{$well}__text {
        padding-top: rem(6);
        font-weight: 300;

        &, p {
          font-size: rem(16) !important;
        }
      }
    }
  }

  &__icon,
  &__text {
    display: table-cell;
    vertical-align: top;
  }

  &__text {
    width: 100%;
    padding-top: rem(2);

    &, p {
      font-size: rem(12) !important;
      font-weight: 300;
      display: inline;
    }
    p {
      position: relative;
      bottom: rem(3);
    }
  }

  &__gutter {
    padding: rem(12) rem(5);
    text-align: center;

    &, p {
      font-weight: 200;
      font-size: rem(16) !important;
    }

    @include breakpoint($medium) {
      padding: rem(22) 0;
      max-width: 60%;
      margin: 0 auto;
    }
  }
  &__close {
    display: table-cell;
    cursor: pointer;
    text-align: center;
    background: none;
    border: none;
    transition: 0.15s;
    font-weight: 500;
    line-height: 1;
    position: absolute;
    right: rem(25);
    top: 50%;
    transform: translateY(-50%);
  }

}

.display-inline {
  display: inline;
}

.infoColor {
  color:$cta-sky
}