.card {
    &.calendar {
        padding: 0;
        margin: rem(20) 0!important;
    }
}
.move-calendar {
    width: 100%;
    text-align: center;
    padding: rem(20) 0;
    border-spacing: 0 rem(10);
    &.selection {
        tr {
            td {
                &.available {
                    background-color: rgba(43, 156, 215, 0.2);
                    &.selected {
                        background-color: #98E8E7;
                    }
                }
            }
        }
    }
    tr {
        td {
            width: 14.25%;
            position: relative;
            &.today {
                position: relative;
                border-radius: 3px;
                &:before {
                    content: '';
                    position: absolute;
                    bottom: rem(5);
                    left: 49%;
                    width:4px;
                    height:4px;
                    border-radius:50%;
                    background: black;

                }
            }
            &.first-range {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }
            &.last-range {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }
            &.in-range {
                font-weight: 600;
                &:hover {
                    background-color: #98E8E7;
                }
            }
            &.selected {
                background-color: #98E8E7;
                button {
                    .toolTip {
                        display: block;
                    }
                }
            }
        }
        button {
            padding: rem(10);
            font-size: 100%;
            width: 100%;
            font-family: inherit;
            border: 0;
            background: none;
            font-weight: inherit;
            -webkit-appearance: none;
            -moz-appearance: none;
            cursor: pointer;
            .toolTip {
                display: none;
                padding: 10px;
                color: $tundora;
                background-color: #f2f4f4;
                font-weight: 500;
                text-align: left;
                position: absolute;
                z-index: 2;
                border-radius: 5px;
                min-width: 200px;
                &.left {
                    left: 120%;
                    top: -30%;
                    &::after {
                        content: "";
                        position: absolute;
                        top: 30px;
                        left: -5px;
                        margin-left: -5px;
                        border-width: 5px;
                        border-style: solid;
                        border-color: transparent #f2f4f4 transparent transparent;
                    }
                }
                &.center {
                    left: -140%;
                    top: 120%;
                    @include breakpoint($medium) {
                        left: -80%;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        top: -10px;
                        left: 50%;
                        margin-left: -5px;
                        border-width: 5px;
                        border-style: solid;
                        border-color: transparent transparent #f2f4f4 transparent;
                    }
                }
                &.right {
                    right: 120%;
                    top: -30%;
                    &::after {
                        content: "";
                        position: absolute;
                        top: 30px;
                        right: -10px;
                        margin-left: -5px;
                        border-width: 5px;
                        border-style: solid;
                        border-color: transparent transparent transparent #f2f4f4;
                    }

                }
            }
            &:focus {
                .toolTip {
                    display: block;
                }
            }
        }
    }
    .available {
        background-color: #4EA3DE;
        cursor: pointer;
    }
}