$spacing-sizes: (
  xxs: 5,
  xs: 20,
  sm: 30,
  md: 50,
  lg: 70,
  xlg: 100
);
@function spacing($size, $scale: 1) {
  @return rem( (map-get($spacing-sizes, $size) * $scale) );
}

@mixin flex-wrap-fix($flex-basis, $max-viewport-width: 2000px) {
    flex-grow: 1;
    flex-basis: $flex-basis;
    max-width: 100%;

    $multiplier: 1;
    $current-width: 0px;

    @while $current-width < $max-viewport-width {
        $current-width: $current-width + $flex-basis;
        $multiplier: $multiplier + 1;

        @media(min-width: $flex-basis * $multiplier) {
            max-width: percentage(1/$multiplier);
        }
    }
}