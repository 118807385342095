/* TOOLTIP! */

.tooltip {
  @include transform(translate3d(0, -10%, 0));
  .is-phone &,
  .is-tablet & {
    display: none;
  }
  opacity: 0;
  visibility: hidden;
  overflow-y: scroll;
  display: none;
  position: absolute;
  bottom: 10px;
  z-index: 1;
  padding: rem(18);
  width: rem(400);
  max-height: 200px;
  text-align: left;
  line-height: rem(18);
  color: $prepaid-text;
  background: #f2f4f5;
  box-shadow: 0 0 .75rem rgba(0, 0, 0, .65);
  transition: opacity .4s ease-in-out .5s,
  visibility .4s ease-in-out .5s;
  &,
  p {
    font-size: rem(12)!important;
  }
  a {
    font-size: rem(12);
  }
  &-parent {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 140px;
    display: inline;
    .is-phone &,
    .is-tablet & {
      max-width: none;
    }
  }
  .tooltip-parent:hover>& {
    opacity: 1;
    visibility: visible;
    transition: opacity .4s ease-in-out 2s, visibility .4s ease-in-out 2s;
  }
  &[tabindex="-1"]:focus {
    outline: none;
  }
}

@include breakpoint($medium) {
  .tooltip {
    display: block;
    &--left,
    &--right {
      width: rem(296);
    }
  }
}

@include breakpoint($large) {
  .tooltip {
    &--left,
    &--right {
      width: rem(400);
    }
  }
}
