.feat-programs {
    z-index: 1;
    position: relative;

	&__slider {
		display: block;

		// Slightly weird logic. Extends width of slider to be edge-to-edge of accordion.
		width: calc(100% + #{spacing('xs', 2)});
		margin-left: spacing('xs', -1);
		margin-right: spacing('xs', -1);

		margin-bottom: spacing('xs', 0.5);
		cursor: grab;
	}

	// &__item,
	.slick-slide {
		// flex: 0 0 auto;
		display: inline;
		margin-right: spacing('xxs');

		opacity: 0.7;
		transition: opacity 0.2s ease-in-out;
		&.slick-active { opacity: 1; }
        max-width: 109px;
        @include breakpoint($medium) {
            max-width: unset;
        }
	}

	.slick-list {
		// Adding necessary padding from accordion into the slider
		padding-left: spacing('xs');
	}

	&__image {
		margin-bottom: spacing('xxs');
	}

	&__channel {
		flex-wrap: nowrap;
		align-items: center;
        max-width: 115px;
        height: 40px;

		img, svg {
			width: 40px;
			height: auto;
			margin-right: spacing('xxs', 0.5);
		}
	}

	&__title {
		font-size: rem(11);
		line-height: 1;
		max-height: rem(22);
		overflow: hidden;
		text-overflow: ellipsis;
        max-width: 60%;
        margin-left: 5px;
	}

    &__heading {
        margin-bottom: rem(20);
    }

    &__modal__item {
        display: inline-block;
        margin: rem(5);
        width: 29%;
        margin-bottom: rem(20);
        img {
            margin-bottom: rem(10);
        }
        &__channel {
            flex-wrap: nowrap;
            align-items: center;

            img, svg {
                width: 40px;
                height: auto;
                margin-right: spacing('xs', 0.5);
            }
        }
        @include breakpoint($large) {
            width: 22%;
            margin-right: rem(6);
            margin-left: rem(6);
            margin-bottom: rem(50);
        }
    }
    .package-channels {
        &__heading {
            margin-bottom: rem(20);
          margin-top: rem(20);
        }
    }
}
