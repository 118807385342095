
%btn {
    cursor: pointer;
    -webkit-appearance: none;
    border: 0;
    display: inline-block;
    text-decoration: none;
}
.align-center{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3px;
}


.button--container {
    text-align: center;
    font-size: rem(12);
    font-weight: 500;
    margin-top: rem(20);
    button {
        &.button {
            min-width: rem(210);
            &--link {
                margin: 0;
            }
        }
    }
}

input, button {
    font-family: XfinityStandard, Helvetica,Arial,sans-serif;
}

.button {
    @extend %btn;
    color: white;
    fill: white;
    padding: .8125rem 1.0625rem;
    margin: 0 auto;
    background: $cta-blue;
    text-align: center;
    font-size: rem(14);
    line-height: 1.2;
    font-weight: 400;
    width: auto;
    min-width: 7.8125rem;
    max-width: 100%;
    border-radius: rem(25);
    transition: background-color 200ms, color 200ms, opacity 200ms;
    &.right {
        position: absolute;
        margin: 0;
        right: rem(15);
        bottom: rem(20);
        min-width: rem(150);
        @include breakpoint($medium) {
            right: rem(35);
        }
    }

    &:hover {
        background: darken(desaturate($cta-blue, 1.50), 4.0);
        color: white;
        fill: white;
        text-decoration: none
    }

    &:disabled {
        background-color: transparent;
        border: 1px solid $alto;
        color: $alto;
    }

    &--link {
        padding: 0;
        // text-decoration: underline;
        font-size: rem(12);
        font-weight: 500;
        min-width: 0;
        margin: 0 rem(10);
        cursor: pointer;

        &, &:hover {
            color: $cta-blue;
            background-color: transparent;
            border: 0;
        }

        &--large {
            padding: 0;
            // text-decoration: underline;
            font-size: rem(14);
            font-weight: 500;
            text-transform: uppercase;
            min-width: 0;
            cursor: pointer;

            &, &:hover {
                color: $cta-blue;
                background-color: transparent;
                border: 0;
            }
        }

        &--underline {
            padding: 0;
            // text-decoration: underline;
            font-size: rem(12);
            font-weight: 500;
            min-width: 0;
            margin: 0 rem(10);
            cursor: pointer;
            text-decoration: underline;

            &, &:hover {
                color: $cta-blue;
                background-color: transparent;
                border: 0;
            }
        }

        &--left{
            margin-left:0;
        }

    }

    &.button--wide {
        @include breakpoint($tiny) {
            min-width: rem(260);
        }
    }
    &.button--hollow {
        background: transparent;
        color: $prepaid-text;
        border: 1px solid $cta-blue;
        &-white {
            display: block;
            background: transparent;
            color: $white;
            border: 1px solid $white;
            &:hover {
                background: darken(desaturate($tundora, 1.50), 4.0);
            }
        }
    }
    &.button--inline {
        margin-right: rem(10);
    }
}

button.form-submit-button,
input[type="button"].form-submit-button,
input[type="submit"].form-submit-button,
.button.form-submit-button {
    display: block;
    margin: rem(30) auto 0;
    max-width: 100%;

    @include breakpoint($tiny) {
        min-width: rem(260);
    }
    @include breakpoint($medium) {
        margin-left:0;
        margin-top: rem(40);
    }
}

a.form-submit-button.form-submit-button {
    display: inline-block;
}

.back-button {
    text-align: left;
    top: rem(0);
    left: rem(20);
    color: $black;
    text-decoration: none;
    padding-left: rem(20);
    background: none;
    outline: none;
    border: none;
    &::before {
        border-style: solid;
        border-width: 1px 1px 0 0;
        content: '';
        display: inline-block;
        height: 10px;
        width: 10px;
        top: 4px;
        left: 0;
        transform: rotate(-135deg);
        border-color:$black
    }
}


.confirm-button{
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    height: 10rem;
    width: 10rem;
    border-radius: 9rem;
    color: white;
    fill: white;
    margin: 0 auto;
    background: #00619a;
    text-align: center;
    font-size: 0.875rem;
    font-weight:800;
    border:none;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    z-index:1;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
    &:focus{
        border:none;
        outline:none;
    }
    &--animation{
        position:absolute;
        z-index:0;
        height: 10rem;
        width: 10rem;
        border-radius: 10rem;
        margin: 0 auto;
        background: #00619a;
        opacity:1;
        transform: translate(-50%, -50%);
        left:50%;
        top:50%;
        &.active:after{
            height: 14rem;
            width: 14rem;
            border-radius: 14rem;
            transform: translate(-50%, -50%);
            left:50%;
            top:50%;
            opacity:0.5;
            background: #00619a;
            position:absolute;
            content:"";
        }
    }
    &--check{
        position:absolute;
        z-index:2;
        height: 14rem;
        width: 14rem;
        border-radius: 14rem;
        margin: 0 auto;
        background-image: url('../../assets/img/icon-check-white.svg');
        background-size: 8em;
        background-repeat: no-repeat;
        background-color: #00619a;
        opacity: 1;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        background-position: 50%;
    
        &.active:after{
            height: 14rem;
            width: 14rem;
            border-radius: 14rem;
            transform: translate(-50%, -50%);
            left:50%;
            top:50%;
            opacity:0.5;
            background: #00619a;
            position:absolute;
            content:"";
        }
    }

}

.button-reset {
    @extend %btn;
    background-color: transparent;
}