.reconnect{
  .card{
    &__header-title{
      padding-left:0;
      padding-right:0;
      padding-bottom: rem(15);
      margin-bottom:0;
      max-width: 100%;
    }
    &__header-subtitle{
      margin:0;
    }
    &__address-status{
      background:#111;
      &--content{
        color:#fff;
        padding: 0.9375rem 4rem;
        font-weight:700;
        position: relative;
        &:before{
          content:"";
          position: absolute;
          height:15px;
          width: 15px;
          background-color: green;
          border-radius:20px;
          left:2.5rem;
          top:1.25rem;
        }
      }
    }
    .payment-method {
      padding: 1.25rem 0.9375rem 1.875rem;
    }
    .line-item{
      &--title{
        border-bottom: 2px solid #e5e5e5;
        padding-bottom: 0.625rem;
        .button--link{
          margin:0;
        }
      }
      .price-full{
        position: relative;
        &--discount{
          color:#999;
          &:after{
            content:"";
            height:1px;
            width:100%;
            background-color:#ff0000;
            position:absolute;
            left:0;
            top:50%;
            transform:rotate(170deg)
          }
        }
        &--refill{
          display:block;
        }
      }
      &.sub{
        width:50%;
        transform: translateX(100%);
      }
      &.separator{
        border-bottom: 2px solid #e5e5e5;
        padding-bottom: 0.625rem;
      }
    }
  }
  &-label {
    font-size: rem(16);
    font-weight: 300;
  }

  .reconnect-radio-list{
    margin-left:0;
    margin-top: rem(20);
    padding-left: rem(15);
    padding-right: 6rem;
    li{
      list-style: none;
      position: relative;
    }
    li:not(:first-of-type){
        margin-top:20px;
    }
    &--price{
        display: inline-block;
        font-weight: 800;
        padding-left: 30px;
        font-size: 20px;
        line-height: 14px;
        position: absolute;
        right: -5rem;
        top: 0.3rem;
    }
    &-title {
        color: black;
        font-weight: 500;
        list-style-type: none;
    }
  }

  .back-btn{
    text-align: left;
    margin: 0;
    padding:0;
    margin-left: rem(15);
    position: relative;

    &:before{
      content:"";
      background-image: url('../../assets/img/carrot-down.svg');
      transform: rotate(
        -270deg
        );
            height: 12px;
            width: 12px;
            background-size: contain;
            position: absolute;
            left: -1.1rem;
            top: 0;
            background-repeat: no-repeat;
    }
  }
  .checkbox__label{
    position:relative;
  }

}