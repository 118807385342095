.dashboard {
    .main {
        padding: 0 0 100px 0;
    }
    h1.page__title {
        margin: rem(30) rem(20);
        @include breakpoint($large) {
            margin: rem(30) 0;
        }
    }

    &-account-information {
        h1.page__title {
            text-align: center;
            @include breakpoint($large) {
                text-align: left;
          }
        }
        .card {
            margin: 0 0 rem(20);
            &.padding-bottom {
                padding: rem(30) rem(30) rem(60) rem(30);
            }
            padding: rem(30);
        }
        .payment-method .card {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .modal__body__content {
        h2:first-child {
            margin-top: 0;
        }
    }
    input + .slider span {
        text-transform: uppercase;
    }

    &__reset-device {
        .reset-intro {
            display: flex;
            flex-direction: column;
            .button {
                margin: 0 auto;
            }
        }
        .reset-success {
            h3 {
                display: flex;
                align-items: center;
                .check {
                    width: rem(40);
                    height: rem(40);
                    margin-right: rem(10);
                }
            }
        }
    }

    .error-content {
        text-align: center;
        img {
            margin-top: rem(60);
            margin-bottom: rem(20);
        }
    }
}