.account-block {
  padding: 0 0;
}

.modal-button-container {
  position: relative;
  @include breakpoint($large) {
    max-width: 70%;
  }
}
.modal-button {
  position: absolute;
  top: 5px;
  right: 0;
}
.information-label {
  line-height: 30px;
}
.cima-label {
  position: absolute;
  top: -3px;
  right: 0;
  text-transform: uppercase;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
  .modal {
    text-transform: none;
  }
}
.cima-verified {
  background-color: #47C684;
}
.cima-unverified {
  background-color: #FFA700;
  border: none;
  line-height: rem(18);
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  img {
    margin-left: rem(5);
    margin-top: rem(2);
    vertical-align: top;
  }
  .modal-item {
    display: inline;
  }
}
.cima-modal-button {
  padding: 0;
  background-color: transparent;
  display: inline;
  margin-left: rem(5);
  min-width: 0;
  border: none;
  line-height: 1;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  img {
    vertical-align: top;
  }
}
.review-label {
  color: $prepaid-text;
  font-size: rem(12);
  line-height: 18px;
  font-weight: 500;
  margin-bottom: rem(8);
}
