.refill {
    .accordion.accordion--with-input{
        position:relative;
    }
    .accordion__tab{
        padding-top: 0;
        padding-bottom: 0;
    }
    .order-confirm-accordion__tab{
        padding-top: 0.9375rem;
        padding-bottom: 0.9375rem;
    }
    .accordion__tab--content{
        padding: 1rem 3rem;

    }
    .accordion--with-input.dashboard .accordion__tab {
        padding: rem(20) 0 rem(10) rem(20);
    }

    .prepaid-tv-wrapper{
        z-index: 2;
        position: relative;
        height: 100%;
        width: 100%;
        padding-top:8rem;
        &--header{
            padding:2rem;
            z-index:2;
            position: absolute;
            top:1rem;
            button{
                margin-left:0;
            }
        }
        &:before{
            content:attr(data-title);
            width:100%;
            padding:0.3rem;
            background:#00619a;
            position: absolute;
            color:#ffffff;
            z-index:2;
            top:0;
        }
        &:after{
            content:"";
            border: 4px #00619a solid;
            background:rgba(255,255,255,0.5);

            height: 100%;
            width: 100%;
            position: absolute;
            z-index:1;
            top:0;
        }
        .button--hollow{
            position: absolute;
            background:white;
            left:50%;
            top:75%;
            transform:translate(-50%, -25%);
            z-index:4;
        }
    }
    .prepaid-tv-footer{
        position:relative;
        &:after{
            content:"";
            position: absolute;
            height:50%;
            width:100%;
            z-index:1;
            background:rgba(255,255,255,0.5);
            bottom:0;
        }
        .button--hollow{
            position: absolute;
            background:white;
            left:50%;
            top:75%;
            transform:translate(-50%, -25%);
            z-index:4;
        }
    }


    .prepaid-modal{
        position: fixed;
        background: white;
        width: 100%;
        min-height: 100%;
        margin: 20px;
        z-index: 25;
        overflow-y: scroll;
        top:0;
        &--content{
            position: absolute;
            top:0;
            z-index:100;
            padding:5rem 2.5rem 0.9375rem 1.25rem;
            overflow: auto;
            background:white;
            width:calc(100% - 40px);
        }
        .close-btn{
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 30;
            background:none;
            border:none;
            line-height:20px;
            img{
                margin-left:10px;
            }
        }
        .content-area{
            top: 80px;
            right: 30px;
            z-index:26;
            left:60px;
            .promo-image{
                width:100%;
                height:auto;
                margin-bottom:2rem;
            }
        }

        &:after{
            content:"";
            background:rgba(0,0,0,0.8);
            height:100%;
            width:100%;
            position: fixed;
            top:0;
            left:0;
            z-index:1;
        }
        .logo-grid{
            margin-top:1rem;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(82px, 1fr));
            grid-gap: 1rem;
            .logo{
                border:1px solid #BABABA;
                height:82px;
                width:82px;
                position: relative;
                img{
                    position: absolute;
                    top:50%;
                    left:50%;
                    transform: translate(-50% , -50%);
                }
            }

        }

    }

    .accordion__tab {
        &--refill-special {
            margin-top: rem(16);
        }
    }

    .accordion__tab--cols{
        padding-top:5px;
        padding-left: rem(40);
        display:flex;
        align-items: center;
        .col-left{
            font-weight:800;
            sup, h1{
                display: inline-flex;
            }
            h1{
                display: inline;
                font-weight: 800;
                font-size: 36px;
            }
            sup{
                top:-0.8rem;
            }
            &.selector-title{
                font-size:12px;
            }
        }
        .col-right{
            font-size: 12px;
            line-height: 16px;
            padding-left: 5px;
            &.selector-buttons{
                border: 1px solid #BABABA;
                border-radius: 20px;
                padding: 0.3rem;
                margin: 0.3rem;
                position: relative;
                display: flex;
                align-items: center;
                button{
                    border:none;
                    &:focus{
                        outline:none;
                    }
                }
                .selector-button{
                    background-image: url("../../assets/img/chevron-circle-blue.svg");
                    background-repeat:no-repeat;
                    height:24px;
                    width:24px;
                    border-radius:30px;
                    background-size:contain;
                    &:disabled {
                        opacity: .3;
                        filter: grayscale(1);
                    }
                }
                .selector-count{
                    margin: 0 5px;
                }
                .selector-buttons--right{
                    transform: rotate(180deg);
               }

                .accordion__icon{
                    position: relative;

                }
            }
        }
    }

    .accordion__content{
        &.card {
            padding: 2rem;
        }
        background:white;
        &:after{
            content:none;
        }
        .button--link{
            padding-left:20px;
        }
        .no-padding {
            padding: 0;
        }
        .no-margin {
            margin: 0;
        }
    }
    .accordion__status{
        position: absolute;
        height:32px;
        width:32px;
        left: 1rem;
        top: 1rem;
        background-repeat: no-repeat;
        background-size: contain;
        &--expired{
            background-image: url("../../assets/img/check-box-off.svg");
        }
        &--active{
            background-image: url("../../assets/img/check-box-active.svg");
        }
        &--new{
            background-image: url("../../assets/img/check-box-empty.svg");
        }
        &--disabled{
            &:after{
                content:"";
                top:0;
                left:0;
                background-color:rgba(white,0.7);
                height:100%;
                width:100%;
                position: absolute;
                z-index:2;
                pointer-events: all;
            }
        }
    }
    .accordion__tab.expanded{
        border-left: 6px solid #00619a;
        .accordion__icon{
            transform: rotate(180deg) translateY(50%);
        }
    }

    .button--hide{
        display: none;
    }

    .subtitle{
        margin-bottom:0;
    }

    .cart {
        &__display-large {
            display: none;
            @include breakpoint ($large) {
                display: block;
            }
        }
        &__display-small-medium {
            display: block;
            @include breakpoint ($large) {
                display: none;
            }
        }
    }
    .sticky-cart {
        .card {
            margin-left: -20px;
            margin-right: -20px;
        }
    }
    .card {
        margin: 0;
    }

    .refill-radio-list{
        margin-left:0;
        margin-top: rem(20);
        li:not(:first-of-type){
            margin-top:20px;
        }
        &--price{
            display: inline-block;
            font-weight: 800;
            padding-left: 30px;
            font-size: 20px;
            line-height: 14px;
            position: absolute;
            right: 20px;
        }
        &__unavailable-notice{
            color: black;
            text-align: center;
            padding: 3rem;
            margin-top: 2rem;
            background: #F2F4F4;
            width: 100%;
        }
        &-title {
            color: black;
            font-weight: 500;
            list-style-type: none;
        }

    }

    input[type="radio"] {
        display:none;
    }

    input[type="radio"] + label {
        color:black;
        font-size:14px;
    }

    input[type="radio"] + label span {
        display:inline-block;
        width:24px;
        height:24px;
        margin:-1px 4px 0 0;
        vertical-align:middle;
        background:white -38px top no-repeat;
        border-radius:24px;
        cursor:pointer;
        border:1px solid #BABABA;
    }

    input[type="radio"]:checked + label span {
        background:#00619A;
        position: relative;
        &:after{
            height:10px;
            width:10px;
            content:"";
            background:white;
            position: absolute;
            border-radius: 10px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    input[type="radio"].non-selectable + label span {
        display:none;
    }
    .main__desktop-sidebar-right {
        .status {
            &--title {
                font-size: rem(18);
                font-weight: 500;
                margin-bottom: rem(10);
            }
            &--date {
                font-size: rem(16);
                display: inline-block;
            }
            button {
                display: inline-block;
                margin-left: rem(15);
            }
        }
    }

}
