.mobile-review {
  .card--order-subtotal {
    padding: rem(15) rem(2);
  }
  .card--order-total {
    padding: rem(15) rem(2);
  }
}

#review-order {
  .card.validation-error {
    .checkbox__label {
      &:before {
        margin-top: rem(4);
      }
    }
  }
  .checkbox__label {
    &:before {
      margin-top: rem(8);
    }
  }
}
