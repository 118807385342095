.totals {
    width: 100%;

    &__caption {
        text-align: left;
    }

    &__edit {
        position: absolute;
        right: 0; top: 0;
        line-height: rem(35);
        font-weight: 500;
    }

    th {
        text-align: left;
    }
    th, td {
        padding: 0;
    }
}