.spanish-broadband-facts {
    .bb-label-ui-charges-section{
        letter-spacing: -0.2px;
    padding: 10px 0px 4px;
    border-bottom: 1px solid #141417;
    height: auto;
    overflow: auto
    }
    .bb-facts-display-font1{
        font-family:'xfinitybrown', arial, helvetica, sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 23.4px;
        letter-spacing: -0.02px;
    }
    .bb-facts-body-font1{
        font-family: 'dmsans', arial, helvetica, sans-serif;;
        font-size: 18px;
    font-weight: 400;
    line-height: 21px;
}

.bb-facts-body-font3 {
    font-family: 'dmsans', arial, helvetica, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.bb-facts-text-neutral-base {
    color: #141417;
}

.bb-facts-text-expressive {
    background-image: linear-gradient(90deg, #28156b, #7c01bd);
    color: transparent;
    background-clip: text;
}

    .bb-facts-inline-block{
        display: inline-block;
    }
    .bb-facts-font-bold {
        font-weight: 700;
    }
    .bb-facts-keep-right {
        float: right;
    }
    .bb-facts-display-table {
        display: table;
    }
    .bb-facts-description {
        padding-top: 6px;
        padding-bottom: 6px;
    }
    .bb-facts-border-bottom {
        border-bottom: 1px solid #62626c26;
    }
    .bb-facts-text-neutral-down {
        color: #484851;
    }
    .bb-facts-clear-both {
        clear: both;
    }
    .bb-facts-font-italic {
        font-style: italic;
    }
    .bb-facts-full-width {
        width: 100%;
    }
    .bb-facts-padding-vertical {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .bb-facts-display-table-row {
        display: table-row;
    }
    .bb-facts-subsection-inner-element {
        padding-left: 18px;
    }
    .bb-facts-display-table-cell {
        display: table-cell;
    }
    .bb-facts-text-right {
        text-align: right;
    }
    h1, h2, h3, h4, h5, h6, p, section, article, header, footer, table, tr, th, td {
        padding: unset;
        margin: unset;
        text-align: unset;
        font-weight: unset;
        font-size: unset;
    }
}