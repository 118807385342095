.progress-bar {
    $height: rem(7);
    padding: 0 rem(15);
  
    &__title {
      color: $tundora;
      font-size: rem(12);
      font-weight: 500;
      margin: 0;
      padding: rem(20) 0 rem(15) 0;
  
      @include breakpoint($large) {
        // padding: rem(15) 0;
      }
    }
    &__width-constraint {
      @include breakpoint($large) {
        padding: 0 rem(115);
      }
    }
  
    &__elm {
      margin: 0 rem(-15);
      // width: 100%;
      height: $height;
      // background: linear-gradient(to left, #3FBB99 5%, #2B9CD8 95% ); Not sure which gradient is correct
      background: linear-gradient(to left, #47C684 0%, #57BAEE 52.5%, #2B9CD8 100% );
      position: relative;
  
      .progress-bar__empty {
        content: '';
        height: $height;
        width: 100%; // start progress bar at zero
        position: absolute;
        right: 0;
        top: 0;
        background: $alto;
        border-top: rem(1.5) solid $black-haze;
        border-bottom: rem(1.5) solid $black-haze;
        transition: width 0.33s cubic-bezier(0.57, -0.02, 0.71, 0.76);
      }
  
    }
  }