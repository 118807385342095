.message {
    $block: &;

    padding: rem(20);
    margin: rem(20) 0;

    &__icon { //svg
        width: rem(40);
        height: rem(40);
    }

    &__link-callout {
        font-weight: 500;
        &, &:hover {
            color: black;
        }
    }

    &--warning {
        color: black;
        fill: black;
        background-color: $warning;

        #{$block}__link-callout {
            font-size: rem(14);
        }
    }

    &--success {
        color: white;
        fill: white;
        background-color: $success;

        .message__link-callout {
            &, &:hover {
                color: white;
            }
        }
    }

    &--error {
        color: white;
        fill: white;
        background-color: $error;

        .layout-cols-2__left {
            width: rem(40); // keep flush with other icons
        }

        .message__icon { width: rem(28); }
    }
}