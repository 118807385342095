
@keyframes slideInFromBottom {
    0% {
        transform: translateY(20%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes pulse_animation {
    0% { transform: scale(1); }
    30% { transform: scale(1); }
    40% { transform: scale(1.08); }
    50% { transform: scale(1); }
    60% { transform: scale(1); }
    70% { transform: scale(1.05); }
    80% { transform: scale(1); }
    100% { transform: scale(1); }
}
.service-status {
    min-height: rem(200);
    .dashboard & {
        min-height: rem(325);
        padding: rem(40) 0;
        text-align: center;
    }
    .autoEnrollSection {
        font-size: rem(20);
        font-weight: 900;
        padding: rem(25) rem(20);
        text-decoration: none;
        cursor: pointer;
    }
    .autoEnrollSection a {
        color: $white;
        text-decoration: none;
    }
    padding: rem(20) 0;
    background: $mine-shaft url(../..//assets/img/status-background-M.png) no-repeat;
    background-size: cover;
    text-align: left;
    color: $white;
    @include breakpoint($medium) {
        background: $mine-shaft url(../..//assets/img/status-background-D.png) no-repeat;
        background-size: cover;
        padding: rem(20);
        .dashboard & {
            padding: rem(40) 0;
        }
    }

    .pulse {
        animation-name: pulse_animation;
        animation-duration: 3000ms;
        transform-origin:70% 70%;
        animation-iteration-count: 2;
        animation-timing-function: linear;
    }
    &__expired {
        text-align: center;
        .icon.icon-expired {
            height: rem(115);
            width: rem(115);

            @include breakpoint($large) {
                height: rem(150);
                width: rem(150);
            }
        }
        &-warning {
            padding: rem(20) 0;
            font-size: rem(18);
            font-weight: 200;
            @include breakpoint($large) {
                font-size: rem(24);
            }
        }
    }
    &__recheck-warning{

    }
    &__promo{
        color:#999999;
        padding-top: rem(20);

    }
    .button--link--underline {
        color: $cta-sky;
    }
    .button--hollow-white {

        min-width: rem(190);
        margin-top: rem(30);
    }

    &__active {
        position: relative;
        text-align: left;
        &-item {
            padding: rem(0) rem(20);
            margin-top: rem(10);
            position: relative;
            display: block;
            width: 100%;
            min-height: rem(100);
            .dashboard & {
                min-height: rem(125);
            }
            background-color: rgba(0,0,0,0.2);
            animation: slideInFromBottom ease 2s;
            @include breakpoint($medium) {
                display: inline-block;
                width: 49%;
                min-height: rem(100);
                .dashboard & {
                    min-height: rem(150)
              }
            }
          &.detail {
            background: none;
            padding: 0;
            margin: 0;
            min-height: rem(90);
            -webkit-animation: none;
            -moz-animation: none;
            -o-animation: none;
            -ms-animation: none;
            animation: none;

            .service-status__active-item-content {
              color: $tundora;
              &::before {
                display: none;
              }
            }
          }
            &-internet {
                @include breakpoint($medium) {
                    margin-right: 1%;
                }
            }
            &-content {
                display: inline-block;
                vertical-align: top;
                margin: rem(15) 0;
                width: 60%;
                text-decoration: none;
                color: $white;
                margin: 0;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 35%;
                .dashboard-xitv & {
                    left: 20%;
                    @include breakpoint($medium) {
                        left: 10%;
                    }
                    @include breakpoint($large) {
                        left: 8%;
                    }
                    color: $tundora;
                    &::before {
                      display: none;
                    }
                }
                &::before {
                    border-style: solid;
                    border-width: 1px 1px 0 0;
                    content: '';
                    display: inline-block;
                    height: 20px;
                    width: 20px;
                    top: 20px;
                    right: 0;
                    position: absolute;
                    transform: rotate(45deg);
                    @include breakpoint($medium) {
                        top: 50%;
                        transform: rotate(45deg) translate(0, -50%);
                        right: 20px;
                    }
                    @include breakpoint($large) {
                        right: 10px;
                    }
                }
                @at-root {
                    div#{&} {
                        &::before {
                            display: none;
                        }
                    }
                }
                .title {
                    margin: rem(5);
                    font-size: rem(16);
                    font-weight: 700;
                }
                .expiration {
                    margin: rem(5);
                    font-size: rem(14);
                    font-weight: 300;
                    text-transform: uppercase;
                }
                .activation {
                    margin: rem(5);
                    font-size: rem(14);
                    font-weight: 300;
                }
                .button {
                    width: 75%;
                    max-width: rem(150);
                    margin: rem(10) 0
                }
                @include breakpoint($small) {
                    width: 65%;
                    left: 30;
                }
                @include breakpoint($medium) {
                    left: 30%;
                }
                @include breakpoint($large) {
                    left: 25%;
                }
            }
            &-addon {
                .service-status__active-item-content {
                    width: 100%;
                    max-width: 100%;
                    left: auto;
                    &:before {
                        display: none;
                    }
                    .title {
                        display: inline-block;
                        position: absolute;
                        top: 40%;
                        left: 28%;
                        transform: translate(0, -50%);
                        font-weight: 500;
                        @include breakpoint($medium) {
                            left: 25%;
                        }
                        @include breakpoint($large) {
                            left: 20%;
                        }
                    }
                }
                .icon {
                    display: inline-block;
                    width: rem(85);
                    height: rem(85);
                    margin: 0;
                }
            }
        }
        .status-circle {
            @include breakpoint($medium) {
                margin-top: rem(5);
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                z-index: 2;
            }
        }
    }
    &__inactive {
        &-body {
            &-title {
                padding: rem(10) rem(15);
                font-size: rem(16);
                font-weight: 500;
                @include breakpoint($large) {
                    padding: rem(10) 0;
                }
            }
            &-text {
                padding: rem(10) rem(15);
                font-size: rem(16);
                font-weight: 300;
                @include breakpoint($large) {
                    padding: rem(10) 0;
                }

            }
        }

    }
    &__title {
        margin-left: rem(20);
        font-weight: 500;
        position: relative;

        a {
          color: $cta-sky;
        }
        .auto-refill-badge {
            position: absolute;
            top: -20px;
            right: rem(20);
            margin-bottom: 0;
            background: #F2F4F4;
            border-left: 6px solid #47C684;
            border-top: none;
            border-right: none;
            border-bottom: none;
            padding: 8px 10px 8px 10px;
            border-radius: 3px;
            color: $tundora;
            font-size: rem(12);
            font-weight: 500;
            -webkit-appearance: none;
            -moz-appearance: none;
            text-decoration: none;
            cursor: pointer;
            button {
                text-decoration: none;
                color: $tundora;
            }

            @include breakpoint($medium) {
              top: -10px;
            }
        }
        @include breakpoint($larger) {
            margin-left: rem(20);
        }
        &--warning{
            padding:0 1rem 1.5rem 3rem;
            position: relative;
            &:before{
                content:"";
                background-image: url('../../assets/img/icon-alert.svg');
                position: absolute;
                width:30px;
                height:30px;
                left:0;
                background-size:contain;
                background-repeat:no-repeat;
                filter: invert(1);
            }
        }
    }
    &__text {
      margin-left: rem(20);
      padding: 0 1rem 1.5rem 3rem;
    }
    .button--link {
        font-size: rem(14);
        margin: rem(20) 0;
        color: #2B9CD8;
        padding: 0 rem(15);
        @include breakpoint($large) {
            padding: 0;
        }
    }
}
