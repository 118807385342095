.warning-banner {
  background-color: #E3E7E9;
  position: relative;
  padding: rem(28) rem(40) rem(18) rem(40);

  &_text {
    display: table-cell;
    vertical-align: top;
  }

  &_text {
    width: 100%;
    padding-top: rem(2);

    &, p {
      font-size: rem(12) !important;
      font-weight: 400;
      display: inline;
    }
    p {
      position: relative;
      bottom: rem(3);
    }
  }

  .icon {
    position: absolute;
    left: rem(10);
    top: 50%;
    transform: translate(0, -50%);
  }
}
