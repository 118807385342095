.activation-page {
    .main {
      min-height: calc(100vh - 254px);
      padding: rem(60) rem(15);
      @include breakpoint($medium) {
        min-height: calc(100vh - 184px);
      }
    }
    p {
      font-size: rem(14);
      font-weight: 200;
      @include breakpoint($medium) {
        font-size: rem(18);
      }
      a {
        font-size: rem(14);
        text-decoration: none;
        @include breakpoint($medium) {
          font-size: rem(18);
        }
      }
    }
    .header-language-switch {
      display: inline-block;
    }
    .header__account-link {
      margin-left: rem(10);
    }
    .modal-item {
      .button--link--large {
        text-transform: none;
        font-size: rem(12);
        @include breakpoint($medium) {
          font-size: rem(14);
        }
      }
    }
    h4 {
      font-size: rem(14);
      font-weight: 400;
      @include breakpoint($medium) {
        font-size: rem(18);
      }
    }
    .card {
      margin: 0;
    }
    .accordion-refill {
      .accordion__tab {
        font-weight: 300;
        font-size: rem(18);
        color: #303030;
        position: relative;
        &::before {
          content: '';
          height: 100%;
          position: absolute;
          border-left: 1px solid #D8D8D8;
          top: 0;
          left: rem(30);
          z-index: 1;
        }
        &-first {
          &::before {
            height: 50%;
            top: 50%;
          }
        }
        &-last {
          &::before {
            height: 50%;
            top: 0;
          }
        }
      }
      .number {
        display: inline-block;
        position: relative;
        border-radius: 50%;
        width: rem(40);
        height: rem(40);
        padding: rem(8);
        border: 1px solid #D1D1D1;
        color: #303030;
        background-color: white;
        text-align: center;
        font-size: rem(24);
        font-weight: 200;
        margin-right: rem(10);
        vertical-align: middle;
        z-index: 2;
        &:focus {
          border-color: #D1D1D1;
        }
        @include breakpoint($large) {
          padding: rem(4);
        }
        .expanded & {
          border-color: $cta-blue;
          color: $cta-blue;
        }
      }
      img {
        &.check {
          width: rem(40);
          height: rem(40);
          margin-right: rem(10);
          display: inline-block;
          vertical-align: middle;
          position: relative;
          z-index: 2;
          background-color: white;
        }
      }
      .active {
        border-color: $cta-blue;
        .number {
          border-color: $cta-blue;
          color: $cta-blue;
        }
      }
      .expanded {
        border-color: $cta-blue;
        &.accordion__tab {
          color: #303030;
        }
        .number {
          border-color: $cta-blue;
          color: $cta-blue;
        }
        &.complete {
          border-color: $success;
          &:focus {
            border-color: $success;
          }
          .number {
            border-color: $success;
            color: $success;
          }
        }
      }
      .complete {
        border-color: $success;
        &.accordion__tab {
          color: #303030;
        }
        &:focus {
          border-color: $success;
        }
        .number {
          border-color: $success;
          color: $success;
        }
      }
      .accordion__panel {
        padding: 0;
        .form-field__title-example {
          font-weight: 200;
        }
  
        p {
          font-size: rem(14)!important;
          font-weight: 200;
          @include breakpoint($medium) {
            font-size: rem(18)!important;
          }
        }
      }
      .accordion__panel-content {
        padding: rem(20) 0;
      }
      #review-order .checkbox__label {
        position: relative;
        &::before {
          margin-top: rem(5);
        }
        p {
          padding-top: 0;
        }
      }
      #review-order {
        .card {
          margin-bottom: 0;
          padding-top: 0;
          padding-bottom: 0;
        }
        .card__header {
          margin-top: rem(20);
        }
        .checkbox {
          margin: 20px 0;
          .validation-error {
            top: 0;
            margin-bottom: rem(10);
          }
        }
      }
      .card {
        &.cc-form-recap {
          margin: 0 rem(20);
        }
        .cart {
          margin-bottom: rem(30);
        }
        .card {
          h2 {
            padding-left: 0;
          }
          padding: 0;
          width: 100%;
          margin: 0 auto;
        }
      }
      button.next-step {
        display: block;
        min-width: 80%;
        margin: 0 auto;
        @include breakpoint($medium) {
          min-width: rem(125);
          margin-left: 0;
        }
      }
      button {
        &:disabled {
          background-color: white;
          border: 1px solid #D1D1D1;
          color: #D1D1D1;
        }
      }
    }
  
    .faq-answer {
      padding: rem(20) 0;
    }
    .litepay-confirmation {
      .card {
        &.cc-form-recap {
          margin: 0 rem(15);
        }
      }
      .cart {
        .card {
          margin: 0 rem(-15);
          &.card--order-total {
            padding-right: rem(15);
            @include breakpoint($medium) {
              padding-right: rem(35);
  
            }
          }
        }
      }
      .accordion__panel {
        position: relative;
        z-index: 0;
        .print-save {
          z-index: 1;
          right: rem(10);
          top: rem(13);
          @include breakpoint($medium) {
            right: rem(40);
            top: rem(20);
  
          }
        }
      }
      .accordion__faq-group {
        position: relative;
        .link-callout {
          top: rem(14);
          right: rem(15);
          text-transform: none;
        }
      }
    }
    .button-captcha {
      display: block;
      margin: 0 auto;
    }
    @media print {
      #offCanvasTop {
        display: none;
      }
      .litepay-confirmation {
        h1 {
          display: block;
        }
        background: none;
        .cc-form-recap {
          display: block;
          background: none;
          color: black;
          font-size: rem(20);
        }
        .accordion__faq-group {
          display: none;
        }
      }
      .footer {
        display: none;
      }
  
    }
    .cc-form-recap {
      font-size: rem(16);
      svg {
        margin-right: rem(5);
        vertical-align: middle;
        position: relative;
      }
      &-header {
        font-size: rem(14);
        font-weight: 500;
        margin-top: rem(10);
      }
    }
    .service-status__active-item {
      text-align: left;
    }
    .service-status__active-item-content::before {
      display: none;
    }
    .service-status {
      .card {
        color: black;
        label {
          font-size: rem(12);
          font-weight: 400;
          margin-bottom: rem(5);
        }
        .progress-spinner__loader {
          height: 200px;
        }
      }
    }
    .button {
      display: block;
      padding: rem(13) rem(60);
      margin: 0 auto;
      &:disabled {
        background-color: transparent;
        border: 1px solid $alto;
        color: $alto;
      }
      @include breakpoint($medium) {
        margin: 0;
        padding: rem(13) rem(40);
      }
    }
    .modal-button-container {
      position: relative;
      @include breakpoint($large) {
        max-width: 40%;
      }
  }
  .modal-button {
      position: absolute;
      top: 5px;
      right: 0;
  }
  .information-label {
      line-height: 30px;
  }
  .cima-label {
      position: absolute;
      top: -3px;
      right: 0;
      text-transform: uppercase;
      padding: 5px 10px;
      font-size: 12px;
      border-radius: 3px;
      .modal {
        text-transform: none;
      }
  }
  .cima-verified {
      background-color: #47C684;
  }
  .cima-unverified {
      background-color: #FFA700;
      border: none;
      line-height: rem(18);
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      img {
        margin-top: rem(1);
        margin-left: rem(5);
        vertical-align: top;
      }
      .modal-item {
        display: inline;
      }
  }
  .cima-modal-button {
      padding: 0;
      background-color: transparent;
      display: inline;
      margin-left: rem(5);
      min-width: 0;
      border: none;
      line-height: 1;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      img {
        vertical-align: top;
      }
  }
  .error-content {
    text-align: center;
    img {
      margin-bottom: rem(20);
    }
  }
  .account-block {
    padding: rem(10) 0;

    .dashboard & {
      font-size: rem(16);
      font-weight: 200;

      b {
        font-size: rem(14);
      }
    }
  }
  .account-picker-subtitle {
    font-size: rem(16);
    font-weight: 200;
  }
}
.activating-page {
  min-height: 100vh;
}
.modal__body {
  .account-block {
    margin: rem(10) 0 rem(20);
  }
}
#conflict-resolution {
  margin-bottom: rem(70);
  margin-top: rem(10);
  @include breakpoint($large) {
    margin-bottom: 0;
  }
}
.activation-conflict-modal {
  p {
    margin-bottom: rem(20);
    font-size: rem(14);
    font-weight: 200;
  }
  .account-block:last-of-type {
    margin-bottom: rem(30);
  }
}
  