// Primary Colors
$xfinity-red: #c8001d;
$cta-blue: #00619a;
$cta-sky: #2b9cd8;

// Functional Colors
$success: #47c684;
$warning: #ffa700;
$error: #c90318;

// Neutrals
$black: #000;
$cod-gray: #191919;
$prepaid-text: #303030;
$mine-shaft: #303030;
$tundora: #474747;
$alto: #d1d1d1;
$blue-link: #00619a;
$input-outline-blue: #8ecaea;
$fill-grey: #e5e5e5;
$porcelain: #e3e7e9;
$black-haze: #f2f4f4;
$white-haze: #f2f4f4;
$saltpan: #fbfdfc;
$white: #fff;
$disclaimer-background: rgba(0, 0, 0, 0.5);
$alternate-gray: #ebf3f4;
$banner-alert: #e3e7e9;
$merchant-prepaid-green: #57c580;
$ACPurple: #6138F5;
