//The summary at the top of the form
.validation-error-summary {
    color: $error;
    margin-bottom: spacing('sm');
    .small-text-button { display: none; } //it gets pulled into the summary, quick fix
}

//class is applied to any input in error
input.validation-error,
select.validation-error {
    border-color: $error;
    outline-color: $error;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(212,0,0,0.42);
    -moz-box-shadow: 0px 0px 8px 0px rgba(212,0,0,0.42);
    box-shadow: 0px 0px 8px 0px rgba(212,0,0,0.42);
}



//sometimes, a label is added below that input too
label.validation-error {
    position: relative;
    top: rem(-12);
    color: $error;
    font-size: rem(14);
}

.radio-list {
    margin-bottom: rem(20);
    &__item {
        border: 1px solid $alto;
        border-radius: rem(3);
        margin-bottom: rem(6);
        padding:rem(14) rem(16);
    }

    &__label {
        padding-left: rem(31);
        position: relative;
        font-weight: 200;
        font-size: rem(16);
        cursor: pointer;
        &:before,
        &:after {
            content: '';
            position: absolute;
            border-radius: 100%;
            // transition: background 0.0875s ease-in-out;
        }
        &:before {
            left: 0; top: 50%;
            background: #eee;
            width: rem(22);
            height: rem(22);
            @include transform(translateY(-50%));
        }
        &:after {
            left: rem(6); top: 50%;
            background: transparent;
            width: rem(10);
            height: rem(10);
            z-index: 9;
            @include transform(translateY(-50%));
        }
        @at-root input:checked + & {
        &.remove-check {
            &:after {
                left: rem(6); top: 50%;
                background: transparent;
                width: rem(10);
                height: rem(10);
                z-index: 9;
                @include transform(translateY(-50%));
            }
            &:before {
                left: 0; top: 50%;
                background: #eee;
                width: rem(22);
                height: rem(22);
                @include transform(translateY(-50%));
            }
        }
            &:after {
                background: $white;
            }
            &:before {
                background: $blue-link;
            }
        }
    }

    &__input {
        @extend .vh;
        &:focus + .radio-list__label {
            &:before {
                // outline-color: $input-outline-blue;
                // border-color: $input-outline-blue;
                box-shadow: 0px 0px 4px $input-outline-blue;
            }
        }
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {display:none;}
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #666;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #00619a;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
  }
  
  
  .on, .off
  {
    color: white;
    position: absolute;
    transform: translate(0,-50%);
    top: 50%;
    font-size: 11px;
    font-family: Verdana, sans-serif;

    .dashboard-account-information & {
      font-family: 'XfinityStandard';
      font-size: rem(9);
      font-weight: bold;
    }
  }

  .on  {
    display: none;
    left: 10%;
  }
  .off {
      right: 10%;
  }
  
  input:checked+ .slider .on
  {display: block;}
  
  input:checked + .slider .off
  {display: none;}
  
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;}

  /* CB-3090 Whitespace error on mobile view */
  .f-cc-pref-open {
    overflow: visible !important
  }