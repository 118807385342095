.info-stripe {
  background-color: $white;
  margin-bottom: rem(20);
  margin-top: rem(10);
  padding: rem(15);

  &__link {
    display: inline;
  }
  &__text {
    display: inline;
    font-size: rem(12);
    padding-right: rem(3);
  }
  &__title {
    display: block;
    font-size: rem(12);
    font-weight: 700;
    margin-bottom: rem(5);
  }
}